<h5 nz-typography class="text-center text-primary title">Form Settings</h5>
<div>
    <form [autocomplete]="true" class="form" [formGroup]="updateForm" nz-form nzLayout="vertical"
        (ngSubmit)="submitForm()">

        <nz-form-item>
            <nz-form-label nzRequired="true">Title</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter a valid title">
                <input nz-input formControlName="formHeading" type="text" placeholder="form title" />
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzRequired="true">Sub-title</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter a valid sub-title">
                <input nz-input formControlName="formSubHeading" type="text" placeholder="form sub-title" />
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzRequired="true">Submit button text</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter a valid label">
                <input nz-input formControlName="submitButtonLabel" type="text" placeholder="submit button label" />
            </nz-form-control>
        </nz-form-item>

        <div class="form-button">
            <button [disabled]="!updateForm.valid" nz-button nzType="primary" type="submit" class="primary"
                [nzLoading]="loading$ | async">
                Save changes
            </button>
        </div>
    </form>
</div>