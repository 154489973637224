import { Component, Input, inject } from '@angular/core';
import { IFormResponse } from '../../models/qr';
import { NzTableModule } from 'ng-zorro-antd/table';
import { CommonModule } from '@angular/common';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { ResponsesService } from '../../services/responses.service';

@Component({
  selector: 'app-form-responses-table',
  standalone: true,
  imports: [NzTableModule, CommonModule, NzPopconfirmModule, NzTypographyModule],
  templateUrl: './form-responses-table.component.html',
  styleUrl: './form-responses-table.component.scss'
})
export class FormResponsesTableComponent {

  @Input() responses: IFormResponse[] | null = null;
  @Input() formId!: string;

  private responseService = inject(ResponsesService)

  confirmDelete(id: string) {
    this.responseService.deleteResponse(this.formId, id)
  }
}
