<div nz-row nzJustify="center" nzAlign="middle" class="pub-form" #form>
    <div nz-col [nzSpan]="24" [nzXs]="22" [nzSm]="18" [nzLg]="12" [nzXl]="8" [nzXXl]="6">

        <div class="text-center form-header ">
            <h1 nz-typography>
                {{formInfo.displayConfig.formHeading}}
            </h1>
            <h4 nz-typography>{{formInfo.displayConfig.formSubHeading}}</h4>
        </div>
        <form nz-form [formGroup]="formGroup" nz-form nzLayout="vertical" (ngSubmit)="submitForm()">
            <div nz-row [nzGutter]="36">
                <ng-container *ngFor="let field of formFields">

                    <div nz-col [nzSpan]="24">
                        @if (['section'].includes(field.fieldType)) {
                        <h5 nz-typography>{{field.fieldLabel}}</h5>
                        }
                        @else {
                        <nz-form-item>
                            <nz-form-label [nzRequired]="isRequired(field.validations)" [nzFor]="field.fieldId"
                                [nzTooltipTitle]="field.toolTip">
                                {{ field.fieldLabel }}
                            </nz-form-label>
                            <nz-form-control [nzErrorTip]="getErrorMessage(field.fieldId)">

                                <input *ngIf="field.fieldType === 'text'" nz-input [formControlName]="field.fieldId"
                                    [placeholder]="field.placeHolder" [id]="field.fieldId" />

                                <nz-radio-group *ngIf="field.fieldType === 'radio'" [formControlName]="field.fieldId">
                                    @for (option of field.options; track $index) {
                                    <label nz-radio [nzValue]="option">{{ option }}</label>
                                    }
                                </nz-radio-group>

                                <nz-input-number *ngIf="field.fieldType === 'number'" [nzSize]="'large'"
                                    style="width: 100%" [nzMin]="numberFieldProperty('min', field.fieldId)"
                                    [nzMax]="numberFieldProperty('max', field.fieldId)" [nzStep]="1"
                                    [nzPrecision]="numberFieldProperty('precision', field.fieldId)"
                                    [formControlName]="field.fieldId"></nz-input-number>

                                <nz-select *ngIf="field.fieldType === 'select'" [formControlName]="field.fieldId"
                                    [nzDisabled]="field.isDisabled" [nzPlaceHolder]="field.placeHolder">
                                    @for (option of field.options; track $index) {
                                    <nz-option [nzValue]="option" [nzLabel]="option"></nz-option>
                                    }
                                </nz-select>

                                <nz-select class="multi" *ngIf="field.fieldType === 'multiselect'"
                                    [formControlName]="field.fieldId" [nzDisabled]="field.isDisabled"
                                    [nzPlaceHolder]="field.placeHolder" nzMode="multiple" [nzMaxTagCount]="3"
                                    [nzMaxTagPlaceholder]="tagPlaceHolder">
                                    @for (option of field.options; track $index) {
                                    <nz-option [nzValue]="option" [nzLabel]="option"></nz-option>
                                    }
                                </nz-select>

                                <textarea *ngIf="field.fieldType === 'textarea'" nz-input
                                    [formControlName]="field.fieldId" [placeholder]="field.placeHolder"
                                    [nzAutosize]="{ minRows: 3, maxRows: 5 }" [id]="field.fieldId"></textarea>

                                <ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length }}
                                    more</ng-template>
                                <!-- Add more field types here as needed -->
                                <nz-date-picker *ngIf="field.fieldType === 'calendar'" [formControlName]="field.fieldId"
                                    style="width: 100%" [nzSize]="'large'" [nzPlaceHolder]="field.placeHolder" 
                                    [nzDisabled]="field.isDisabled" [nzFormat]="dateFieldProperty('dateformat', field.fieldId)"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                        }
                    </div>
                </ng-container>
            </div>

            <div class="form-buttons">
                <button nz-button nzType="primary" [nzLoading]="loading$ | async"
                    class="submit">{{formInfo.displayConfig.submitButtonLabel}}</button>
            </div>

        </form>
    </div>
</div>