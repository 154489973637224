import { Component, Input, inject } from '@angular/core';
import { IFieldFile, IFormResponse } from '../../models/qr';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { DateAgoPipe } from '../../pipes/date-ago.pipe';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ResponsesService } from '../../services/responses.service';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { RouterModule } from '@angular/router';
import { S3Service } from '../../services/s3.service';
import { CommonModule } from '@angular/common';
import { S3UrlPipe } from '../../pipes/s3-url.pipe';
import { NzModalModule } from 'ng-zorro-antd/modal';

interface IFormResponseFields {
  key?: string;
  fieldId: string;
  stringValue?: string;
  fieldFiles?: IFieldFile[]
}

@Component({
  selector: 'app-qr-list-card',
  standalone: true,
  imports: [NzTypographyModule, DateAgoPipe, NzAvatarModule, NzIconModule, NzSpaceModule, NzCardModule, NzDropDownModule, NzPopconfirmModule, RouterModule, CommonModule, S3UrlPipe, NzModalModule],
  templateUrl: './qr-list-card.component.html',
  styleUrl: './qr-list-card.component.scss'
})
export class QrListCardComponent {

  @Input() response!: IFormResponse & { formResponse: IFormResponseFields[] }
  responseService = inject(ResponsesService)
  s3Service = inject(S3Service)

  previewVisible = false;


  getFieldValueByKey(key: string, responses: any[]) {
    return responses.find(x => x.key === key)?.stringValue || ''
  }

  getImagePath(key: string, responses: IFormResponseFields[]) {
    const res = responses.find(x => x.key === key)?.fieldFiles
    return res ? res[0].filePath : ''
  }

  generateQR(formId: string, respId: string) {
    this.responseService.generateQR(formId, respId);
  }

  deleteResponse(respId: string, formId: string) {
    this.responseService.deleteResponse(formId, respId)
  }

  async getUrl(path: string) {
    const res = await this.s3Service.generatePresignedUrl(path)
    return res;
  }

}
