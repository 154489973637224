import { ResolveFn } from '@angular/router';
import { ResponsesService } from '../services/responses.service';
import { inject } from '@angular/core';
import { IResponseAPIResponse } from '../models/qr';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs/internal/observable/of';

export const responseResolver: ResolveFn<IResponseAPIResponse | null> = (route, state) => {

  const responseService = inject(ResponsesService)
  const formId = route.paramMap.get('formId');
  const responseId = route.paramMap.get('responseId');

  if (formId && responseId && responseService.data$.value.data.length === 0) {
    return responseService.resolveResponses(formId).pipe(
      catchError(error => {
        console.error('Error loading form:', error);
        // redirect to form could not be loaded
        return of(null);
      }))
  } else {
    return of(null);
  }
};
