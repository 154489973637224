import { Component, Input, OnInit, inject } from '@angular/core';
import { FieldType, IFormField, ValidationRules } from '../../models/forms';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { FormBuilderService } from '../../services/form-builder.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';

@Component({
  selector: 'app-update-form-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NzFormModule, NzInputModule, NzSelectModule, NzTypographyModule, NzButtonModule, NzIconModule, NzCheckboxModule, NzInputNumberModule, NzRadioModule],
  templateUrl: './update-form-field.component.html',
  styleUrl: './update-form-field.component.scss'
})
export class UpdateFormFieldComponent implements OnInit {

  @Input() formId!: string;
  @Input() fieldData!: IFormField;
  @Input() validationList!: string[];
  private builderService = inject(FormBuilderService);

  updateForm!: FormGroup
  loading$ = this.builderService.loading$;
  showOptions = false;
  fieldType = FieldType;
  dateFormats: string[] = []
  dateFormats$ = this.builderService.dateFormats$.subscribe(x => {
    console.log(x);
    this.dateFormats = x;    
  })

  validationRules!: FormGroup;

  ngOnInit(): void {
    this.updateForm = new FormGroup({
      fieldId: new FormControl(this.fieldData.fieldId),
      formId: new FormControl(this.formId),
      fieldType: new FormControl(this.fieldData.fieldType),
      placeHolder: new FormControl(this.fieldData.placeHolder),
      label: new FormControl(this.fieldData.fieldLabel, [Validators.required]),
      toolTip: new FormControl(this.fieldData.toolTip),
      disabled: new FormControl(this.fieldData.isDisabled),
      options: new FormArray(this.fieldData.options?.map(x => new FormControl(x)) ?? []),
    })

    this.showOptions = [FieldType.Radio, FieldType.MultiSelect, FieldType.Select].includes(this.fieldData.fieldType)
    this.generateValidationFormControls();
  }

  generateValidationFormControls() {

    this.validationRules = new FormGroup({});

    this.validationList.forEach(validation => {

      switch (validation) {
        case 'required':
          this.validationRules.addControl(validation, new FormControl(this.fieldData.validations.find(x => x.rule === validation)?.value || false));
          break;
        case 'minvalue':
        case 'maxvalue':
        case 'minlength':
        case 'maxlength':
        case 'noofdecimalpoints':
        case 'regex':
        case 'dateformat':
          this.validationRules.addControl(validation + "-check", new FormControl(this.fieldData.validations.find(x => x.rule === validation) ? true : false));
          this.validationRules.addControl(validation, new FormControl({
            disabled: this.fieldData.validations.find(x => x.rule === validation) ? false : true,
            value: this.fieldData.validations.find(x => x.rule === validation)?.value || null
          },));
          break;
        case 'allowdecimals':
        case 'allownegative':
        case 'allowpast':
        case 'allowfuture':
        case 'allowmultiple':
          this.validationRules.addControl(validation, new FormControl(this.fieldData.validations.find(x => x.rule === validation)?.value || false));
          break;

      }

    })

    if (this.fieldData.fieldType === FieldType.Text) {

      let val = null;
      if (this.fieldData.validations.find(x => x.rule === 'alphanumeric')) val = 'alphanumeric'
      if (this.fieldData.validations.find(x => x.rule === 'email')) val = 'email'
      if (this.fieldData.validations.find(x => x.rule === 'number')) val = 'number'

      this.validationRules.addControl(
        'restriction',
        new FormControl(val)
      )
    }
  }

  get options(): FormArray {
    return this.updateForm.get('options') as FormArray;
  }

  addOption(): void {
    this.options.push(new FormControl('', [Validators.required]));
  }

  removeOption(index: number): void {
    this.options.removeAt(index);
  }

  modifyValidation(ev: boolean, fieldName: string) {
    const fc = this.validationRules.get(fieldName) as FormControl
    ev ? fc.enable() : fc.disable();
    if (!ev) fc.reset();
  }

  updateField() {
    const formValue = { ...this.updateForm.getRawValue(), validationRules: [] }
    const validationForm = this.validationRules.getRawValue()

    if (validationForm.required) formValue.validationRules.push({ rule: ValidationRules.REQUIRED, value: 'true' })
    if (validationForm.allowdecimals) formValue.validationRules.push({ rule: ValidationRules.ALLOWDECIMALS, value: 'true' })
    if (validationForm.allownegative) formValue.validationRules.push({ rule: ValidationRules.ALLOWNEGATIVE, value: 'true' })
    if (validationForm.allowpast) formValue.validationRules.push({ rule: ValidationRules.ALLOW_PAST, value: 'true' })
    if (validationForm.allowfuture) formValue.validationRules.push({ rule: ValidationRules.ALLOW_FUTURE, value: 'true' })
    if (validationForm.allowmultiple) formValue.validationRules.push({ rule: ValidationRules.ALLOW_MULTIPLE, value: 'true' })
    if (validationForm['minvalue-check']) formValue.validationRules.push({ rule: ValidationRules.MIN_VALUE, value: validationForm.minvalue })
    if (validationForm['maxvalue-check']) formValue.validationRules.push({ rule: ValidationRules.MAX_VALUE, value: validationForm.maxvalue })
    if (validationForm['minlength-check']) formValue.validationRules.push({ rule: ValidationRules.MIN_LENGTH, value: validationForm.minlength })
    if (validationForm['maxlength-check']) formValue.validationRules.push({ rule: ValidationRules.MAX_LENGTH, value: validationForm.maxlength })
    if (validationForm['noofdecimalpoints-check']) formValue.validationRules.push({ rule: ValidationRules.DECIMALPOINTS, value: validationForm.noofdecimalpoints })
    if (validationForm['regex-check']) formValue.validationRules.push({ rule: ValidationRules.REGEX, value: validationForm.regex })
    if (validationForm['dateformat-check']) formValue.validationRules.push({ rule: ValidationRules.DATE_FORMAT, value: validationForm.dateformat })
    if (validationForm.restriction) formValue.validationRules.push({ rule: validationForm.restriction, value: 'true' })

    this.builderService.updateField(formValue);
  }
}
