import { Component, Input, inject } from '@angular/core';
import { TrashService } from '../../services/trash.service';
import { IFormResponse } from '../../models/qr';
import { CommonModule } from '@angular/common';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'app-form-trash-table',
  standalone: true,
  imports: [NzTableModule, CommonModule, NzPopconfirmModule, NzTypographyModule, NzDividerModule],
  templateUrl: './form-trash-table.component.html',
  styleUrl: './form-trash-table.component.scss'
})
export class FormTrashTableComponent {
  @Input() responses: IFormResponse[] | null = null;
  @Input() formId!: string;

  trashService = inject(TrashService);

  restoreResponse(respId: string) {
    this.trashService.restoreResponse(this.formId, respId)
  }

  deleteResponse(respId: string) {
    this.trashService.deleteResponse(this.formId, respId)
  }
}
