import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilderService } from '../../services/form-builder.service';
import { AddFormFieldComponent } from '../../ui-components/add-form-field/add-form-field.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription, map, tap } from 'rxjs';
import { FormService } from '../../services/form.service';
import { CommonModule } from '@angular/common';
import { FormFieldsListComponent } from '../../ui-components/form-fields-list/form-fields-list.component';
import { IDisplayConfig, IFieldTypes, IFormField } from '../../models/forms';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { UpdateFormFieldComponent } from '../../ui-components/update-form-field/update-form-field.component';
import { FormGeneralSettingsComponent } from '../../ui-components/form-general-settings/form-general-settings.component';


@Component({
  selector: 'app-generic-builder',
  standalone: true,
  imports: [AddFormFieldComponent, CommonModule, FormFieldsListComponent, NzDrawerModule, UpdateFormFieldComponent, FormGeneralSettingsComponent],
  templateUrl: './generic-builder.component.html',
  styleUrl: './generic-builder.component.scss'
})
export class GenericBuilderComponent implements OnInit, OnDestroy {

  private builderService = inject(FormBuilderService);
  private formService = inject(FormService);
  route = inject(ActivatedRoute);
  subscriptions: Subscription[] = []

  formId: string = '';
  formFields: IFormField[] = []
  displayConfig: IDisplayConfig | undefined | null = null
  formFields$ = this.formService.formSubject$.pipe(
    tap(x => { this.displayConfig = x?.displayConfig }),
    map(x => x?.formFields ?? []),
    tap(x => {
      this.formFields = x.sort((a, b) => a.position - b.position)
    })
  );
  fieldMasterData!: IFieldTypes;
  fieldMasterData$ = this.builderService.fieldsData$.pipe(tap(x => {
    if (x) this.fieldMasterData = x;
  }))
  dateFormats: string[] = []
  dateFormats$ = this.builderService.dateFormats$.subscribe(x => {
    this.dateFormats = x;    
  })

  fieldEditorVisible = false;
  generalEditorVisible = false;
  selectedFieldId!: string;
  selectedFormField: IFormField | undefined;

  ngOnInit(): void {
    this.builderService.getFieldsMasterData();
    this.builderService.getDateFormats();
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.formId = params['formId'];
        this.builderService.getFormData(this.formId);
      })
    )
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  openSettingsDrawer(fieldId: string) {
    this.selectedFieldId = fieldId
    this.selectedFormField = this.formFields.find(x => x.fieldId === fieldId);
    this.fieldEditorVisible = true;
  }

  close() {
    this.fieldEditorVisible = false;
  }

  openGeneralSettings() {
    this.generalEditorVisible = true;
  }

  closeGeneral() {
    this.generalEditorVisible = false;
  }
}
