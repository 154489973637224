<form nz-form nzLayout="vertical">
    <div class="text-center form-header">
        <h1 nz-typography>
            {{displayConfig?.formHeading}}
        </h1>
        <h4 nz-typography>
            {{displayConfig?.formSubHeading}}
        </h4>

        <div class="options">
            <button type="button" (click)="openFormGeneralSettings()">
                <span nz-icon nzType="setting" nzTheme="outline"></span>
            </button>
        </div>
    </div>
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        @for (field of fieldList; track $index) {
        <div class="example-box" cdkDrag>
            @if (field.fieldType === fieldType.Section) {
            <h3 nz-typography class="section-heading">{{ field.fieldLabel }}</h3>
            }
            @else {
            <nz-form-item class="form-item">
                <nz-form-label [nzRequired]="isRequired(field.validations)" [nzFor]="field.fieldId"
                    [nzTooltipTitle]="field.toolTip">
                    {{ field.fieldLabel }}
                </nz-form-label>
                <nz-form-control>
                    <input *ngIf="field.fieldType === 'text'" nz-input [placeholder]="field.placeHolder"
                        [id]="field.fieldId" />

                    <nz-radio-group *ngIf="field.fieldType === 'radio'">
                        @for (option of field.options; track $index) {
                        <label nz-radio [nzValue]="option">{{ option }}</label>
                        }
                    </nz-radio-group>

                    <nz-input-number *ngIf="field.fieldType === 'number'" [nzSize]="'large'"
                        style="width: 100%"></nz-input-number>

                    <nz-select *ngIf="field.fieldType === 'select'" [nzDisabled]="field.isDisabled"
                        [nzPlaceHolder]="field.placeHolder">
                        @for (option of field.options; track $index) {
                        <nz-option [nzValue]="option" [nzLabel]="option"></nz-option>
                        }
                    </nz-select>

                    <nz-select class="multi" *ngIf="field.fieldType === 'multiselect'" [nzDisabled]="field.isDisabled"
                        [nzPlaceHolder]="field.placeHolder" nzMode="multiple" [nzMaxTagCount]="3"
                        [nzMaxTagPlaceholder]="tagPlaceHolder">
                        @for (option of field.options; track $index) {
                        <nz-option [nzValue]="option" [nzLabel]="option"></nz-option>
                        }
                    </nz-select>

                    <textarea *ngIf="field.fieldType === 'textarea'" nz-input [placeholder]="field.placeHolder"
                        [nzAutosize]="{ minRows: 3, maxRows: 5 }" [id]="field.fieldId"></textarea>

                    <input *ngIf="field.fieldType === 'calendar'" nz-input [placeholder]="field.placeHolder"
                        [id]="field.fieldId" />

                    <ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length }}
                        more</ng-template>
                    <!-- Add more field types here as needed -->
                </nz-form-control>
            </nz-form-item>
            }
            <div class="options">
                <button type="button" class="drag-handle" cdkDragHandle>
                    <span nz-icon nzType="drag" nzTheme="outline"></span>
                </button>
                <button type="button" (click)="openSetting(field.fieldId)">
                    <span nz-icon nzType="setting" nzTheme="outline"></span>
                </button>
                <button type="button" nz-popconfirm nzPopconfirmTitle="Are you sure delete this field?"
                    nzPopconfirmPlacement="bottom" (nzOnConfirm)="confirmDelete(field.fieldId)">
                    <span nz-icon nzType="delete" nzTheme="outline"></span>
                </button>
            </div>
        </div>

        }
    </div>
</form>