import { Component, Input, inject } from '@angular/core';
import { FieldType, IForm, IFormField, IValidation, ValidationRules } from '../../models/forms';
import { CommonModule } from '@angular/common';
import { NzInputModule } from 'ng-zorro-antd/input';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { FormControl, FormGroup, ReactiveFormsModule, Validator, Validators } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormService } from '../../services/form.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { IFormResponse } from '../../models/qr';

import { Location } from '@angular/common';
import { S3Service } from '../../services/s3.service';


const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

@Component({
  selector: 'app-add-edit-qr-response',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NzFormModule, NzInputModule, NzGridModule, NzUploadModule, NzIconModule, NzButtonModule, NzModalModule],
  templateUrl: './add-edit-qr-response.component.html',
  styleUrl: './add-edit-qr-response.component.scss'
})
export class AddEditQrResponseComponent {

  formService = inject(FormService)
  s3Service = inject(S3Service)
  location = inject(Location);

  loading$ = this.formService.loading$

  @Input() form!: IForm;
  @Input() response: IFormResponse | null = null;
  @Input() editForm = false
  formGroup!: FormGroup;

  fieldType = FieldType;
  previewImage = '';
  previewVisible = false;
  fileList: NzUploadFile[] = []


  generateFormControls(formFields: IFormField[]): FormGroup {
    const formControls: { [key: string]: FormControl } = {};
    formFields.forEach(field => {
      if (field.fieldType === FieldType.Image && this.editForm) {
        const fieldValue = this.response?.formResponse.find(x => x.fieldId === field.fieldId)?.fieldFiles
        if (fieldValue) {
          formControls[field.fieldId] = new FormControl(fieldValue[0].filePath)
          // get path and update url fieldValue in both cases

          this.s3Service.generatePresignedUrl(fieldValue[0].filePath).then(x => {
            this.previewImage = x;
            this.fileList = [
              {
                uid: '-1',
                name: fieldValue[0].fileName,
                status: 'done',
                url: x
              },

            ]
          })

        }
      } else {
        formControls[field.fieldId] = new FormControl(this.editForm ? this.response?.formResponse.find(x => x.fieldId === field.fieldId)?.stringValue : '');
      }
    });
    return new FormGroup(formControls);
  }

  setFormValidations() {
    this.form.formFields.forEach(field => {
      const validators: Validator[] = [];
      if (field.validations.length > 0) {
        // get form control by Id
        const fc = this.formGroup.get(field.fieldId)
        field.validations.forEach(validation => {
          if (validation.rule === ValidationRules.REQUIRED && validation.value === 'true') {
            fc?.addValidators(Validators.required);
          } else if (validation.rule === ValidationRules.EMAIL && validation.value === 'true') {
            fc?.addValidators(Validators.email);
          } else if (validation.rule === ValidationRules.MIN_LENGTH) {
            fc?.addValidators(Validators.minLength(parseInt(validation.value)));
          } else if (validation.rule === ValidationRules.MAX_LENGTH) {
            fc?.addValidators(Validators.maxLength(parseInt(validation.value)));
          } else if (validation.rule === ValidationRules.MIN_VALUE) {
            fc?.addValidators(Validators.min(parseInt(validation.value)))
          } else if (validation.rule === ValidationRules.MAX_VALUE) {
            fc?.addValidators(Validators.max(parseInt(validation.value)))
          }
        });
      }
    })

    this.formGroup.updateValueAndValidity();
  }


  ngOnInit() {
    this.formGroup = this.generateFormControls(this.form.formFields);
    this.setFormValidations();
  }

  submitForm() {

    if (this.formGroup.valid) {
      let formData = new FormData();

      Object.keys(this.formGroup.controls).forEach(key => {
        const control = this.formGroup.get(key);
        if (this.editForm) {
          if (control && control.dirty) {
            formData.append(key, control.value)
          }
        }
        else {
          if (control) {
            formData.append(key, control.value);
          }
        }
      });
      if (this.editForm) {
        this.formService.updateResponse(formData, this.form.id, this.response?.id!)
      } else {
        this.formService.addResponse(formData, this.form.id)
      }

    }

  }

  getErrorMsg(field: IFormField) {
    return field.toolTip
  }

  isRequired(validations: IValidation[]) {
    return validations.some(validation => validation.rule === ValidationRules.REQUIRED && validation.value === 'true')
  }

  handleFile(event: { file: NzUploadFile, type?: string }, fieldId: string): void {
    const formControl = this.formGroup.get(fieldId);
    formControl?.markAsDirty();
    if (event.type === 'removed') {
      formControl?.setValue(null);
    } else {
      formControl?.setValue(event.file.originFileObj);
    }
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file['preview']) {
      file['preview'] = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file['preview'];
    this.previewVisible = true
  };

  cancelForm() {
    this.location.back();
  }
}
