import { Injectable } from '@angular/core';
import { S3RequestPresigner, getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { env } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  private readonly accessKeyId = env.s3Config.accessKey;
  private readonly secretAccessKey = env.s3Config.secretKey;
  private readonly region = env.s3Config.region; // e.g., 'us-east-1'
  private readonly bucketName = env.s3Config.bucket;

  private s3Client!: S3Client;

  constructor() {
  }

  async initializeClient() {
    this.s3Client = new S3Client({
      region: this.region,
      credentials: {
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey
      }
    });
    console.log(this.s3Client, 'client init')
    // console.log(await this.generatePresignedUrl('forms/654f4fa2f1aef43dfa29eed2/66084c847e4f145654e0ba10/qrcodes/66084e747e4f145654e0ba12.png'))
  }

  async generatePresignedUrl(key: string, expirationSeconds = 1800): Promise<string> {
    const command = new GetObjectCommand({ Bucket: this.bucketName, Key: key });
    const url = await getSignedUrl(this.s3Client, command, { expiresIn: expirationSeconds });
    return url;
  }
}
