import { DataType, FieldType, IFormField, ValidationRules } from "../../models/forms";
import { IFieldFile } from "../../models/qr";

export const qrPreviewData: {
    formFields: IFormField[],
    formResponse: {
        key: string | undefined;
        fieldId: string;
        stringValue?: string | undefined;
        fieldFiles?: IFieldFile[] | undefined;
    }[]
} = {
    formFields: [
        {
            fieldId: "a8ecac9c9f3b4c47e20e651e1c08af46",
            fieldType: FieldType.Image,
            dataType: DataType.String,
            fieldLabel: "Photo",
            placeHolder: "Please upload Photo",
            toolTip: "Please upload Photo",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "true"
                },
                {
                    rule: ValidationRules.ALLOW_MULTIPLE,
                    value: "false"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 1
        },
        {
            fieldId: "a67ab989c6d2e0068c50ba29b131b970",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "Name",
            placeHolder: "Please enter Name",
            toolTip: "Please enter Name",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "true"
                },
                {
                    rule: ValidationRules.MIN_LENGTH,
                    value: "3"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "150"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 2
        },
        {
            fieldId: "efc135ea8328da9578d0c4fbaca9fc46",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "Title",
            placeHolder: "Please enter Title",
            toolTip: "Please enter Title",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "true"
                },
                {
                    rule: ValidationRules.MIN_LENGTH,
                    value: "3"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "150"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 3
        },
        {
            fieldId: "dc2d20e6375c52ee06665e2367ead389",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "Email",
            placeHolder: "Please enter Email",
            toolTip: "Please enter Email",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "true"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "150"
                },
                {
                    rule: ValidationRules.EMAIL,
                    value: "true"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 4
        },
        {
            fieldId: "2b426d91a1c8bdd88ae3267d56cb8d8e",
            fieldType: FieldType.Textarea,
            dataType: DataType.String,
            fieldLabel: "Description",
            placeHolder: "Please enter Description",
            toolTip: "Please enter Description",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "false"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "255"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 5
        },
        {
            fieldId: "8151b29773a3f98892c4f08b254a6204",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "Company Name",
            placeHolder: "Please enter Company Name",
            toolTip: "Please enter Company Name",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "true"
                },
                {
                    rule: ValidationRules.MIN_LENGTH,
                    value: "5"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "200"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 6
        },
        {
            fieldId: "80455b174bd0197c9dbf83cf8bf8a96b",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "Telephone No",
            placeHolder: "Please enter Telephone No",
            toolTip: "Please enter Telephone No",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "false"
                },
                {
                    rule: ValidationRules.MIN_LENGTH,
                    value: "5"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "20"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 7
        },
        {
            fieldId: "8342c5b760f6336a4860184451c08d53",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "Mobile No",
            placeHolder: "Please enter Mobile No",
            toolTip: "Please enter Mobile No",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "false"
                },
                {
                    rule: ValidationRules.MIN_LENGTH,
                    value: "5"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "20"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 8
        },
        {
            fieldId: "1333a6886286a6bf7fe4d30aa0355c9f",
            fieldType: FieldType.Textarea,
            dataType: DataType.String,
            fieldLabel: "Address",
            placeHolder: "Please enter Address",
            toolTip: "Please enter Address",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "false"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "255"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 9
        },
        {
            fieldId: "2a6a29f9e9e9235cf90d1ddecdd07cfe",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "WebURL",
            placeHolder: "Please enter WebURL",
            toolTip: "Please enter WebURL",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "false"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "550"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 10
        },
        {
            fieldId: "6f1efea93c8fcceacd20d35d84a3cac3",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "FBURL",
            placeHolder: "Please enter FBURL",
            toolTip: "Please enter FBURL",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "false"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "550"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 11
        },
        {
            fieldId: "486b939ef158e851ad2fce01a15274d1",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "LinkedInURL",
            placeHolder: "Please enter LinkedInURL",
            toolTip: "Please enter LinkedInURL",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "false"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "550"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 12
        },
        {
            fieldId: "28733f8e2c09112587b2f5238ae4f761",
            fieldType: FieldType.Text,
            dataType: DataType.String,
            fieldLabel: "XURL",
            placeHolder: "Please enter XURL",
            toolTip: "Please enter XURL",
            isDisabled: false,
            options: null,
            validations: [
                {
                    rule: ValidationRules.REQUIRED,
                    value: "false"
                },
                {
                    rule: ValidationRules.MAX_LENGTH,
                    value: "550"
                }
            ],
            createdAt: new Date(),
            updatedAt: new Date(),
            position: 13
        }
    ],
    formResponse:
        [
            {
                fieldId: "a8ecac9c9f3b4c47e20e651e1c08af46",
                key: "Photo",
                stringValue: ""
            },
            {
                fieldId: "a67ab989c6d2e0068c50ba29b131b970",
                key: "Name",
                stringValue: "John Doe"
            },
            {
                fieldId: "efc135ea8328da9578d0c4fbaca9fc46",
                key: "Title",
                stringValue: "UX Engineer"
            },
            {
                fieldId: "dc2d20e6375c52ee06665e2367ead389",
                key: "Email",
                stringValue: "hbd@nexusforge.com"
            },
            {
                fieldId: "2b426d91a1c8bdd88ae3267d56cb8d8e",
                key: "Description",
                stringValue: "This is a desc"
            },
            {
                fieldId: "8151b29773a3f98892c4f08b254a6204",
                key: "Company Name",
                stringValue: "Nexus Forge"
            },
            {
                fieldId: "80455b174bd0197c9dbf83cf8bf8a96b",
                key: "Telephone No",
                stringValue: "+91-1231231233"
            },
            {
                fieldId: "8342c5b760f6336a4860184451c08d53",
                key: "Mobile No",
                stringValue: "+91-12312344421"
            },
            {
                fieldId: "1333a6886286a6bf7fe4d30aa0355c9f",
                key: "Address",
                stringValue: "11 st street \n test line2 \n test line 3 "
            },
            {
                fieldId: "2a6a29f9e9e9235cf90d1ddecdd07cfe",
                key: "WebURL",
                stringValue: "https://hbd.com"
            },
            {
                fieldId: "6f1efea93c8fcceacd20d35d84a3cac3",
                key: "FBURL",
                stringValue: "https://facebook.com/hanieldaniel"
            },
            {
                fieldId: "486b939ef158e851ad2fce01a15274d1",
                key: "LinkedInURL",
                stringValue: "https://linkedin.com/hanieldaniel"
            },
            {
                fieldId: "28733f8e2c09112587b2f5238ae4f761",
                key: "XURL",
                stringValue: "https://x.com/hanieldaniel"
            }

        ]
}