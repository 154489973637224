import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { env } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageListenerService {

  canLoadPages$ = new BehaviorSubject<boolean>(false);

  constructor() {
    window.addEventListener('message', (event) => {
      if (event.origin === env.consoleAppUrl) {

        const authToken = event.data.authToken;
        if (authToken) {
          sessionStorage.setItem('authToken', authToken);
          this.canLoadPages$.next(true);
        }
      }
    });

    if (!this.isPublic()) {
      window.opener?.postMessage('ready', env.consoleAppUrl);
    } else {
      this.canLoadPages$.next(true);
    }
  }

  isPublic() {
    return window.location.pathname.startsWith('/forms');
  }
}
