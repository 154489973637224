<div class="top-bar">
    <form nz-form>
        <nz-form-item>
            <nz-form-control>
                <nz-input-group [nzSuffix]="suffixIconSearch">
                    <input type="text" nz-input placeholder="Search..." [(ngModel)]="searchQuery" name="searchQuery"
                        (ngModelChange)="onSearch($event)">
                </nz-input-group>
                <ng-template #suffixIconSearch>
                    <i nz-icon nzType="search"></i>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
    </form>

    <nz-badge [nzCount]="filterConditions.length">
        <button nz-button [nzType]="filterConditions.length ? 'primary' : 'default'" nzSize="large"
            (click)="openFilter()">
            <span nz-icon nzType="filter"></span>
            Additional
            Filters</button>
    </nz-badge>
</div>

<nz-drawer [nzClosable]="false" [nzVisible]="filterVisible" [nzTitle]="title" nzPlacement="right" [nzWidth]="750"
    (nzOnClose)="closeFilter()">
    <ng-template #title>
        <h4 nz-typography>Additional Filters</h4>
    </ng-template>
    <ng-container *nzDrawerContent>
        <form nz-form class="form" [formGroup]="filtersForm" nz-form nzLayout="vertical" class="validation-form">
            <nz-form-label>Filters</nz-form-label>
            <div formArrayName="filterArray">
                <div *ngFor="let filter of filters.controls; let i = index" [formGroupName]="i"
                    class="filter-list-item">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Field is required" class="option-input">
                            <nz-select formControlName="fieldId" placeholder="Select Field"
                                (ngModelChange)="changeConditionValues(i)">
                                @for (item of formFieldData; track $index) {
                                <nz-option [nzValue]="item.fieldId" [nzLabel]="item.fieldLabel"></nz-option>
                                }
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Field is required" class="option-input">
                            <nz-select formControlName="condition" placeholder="Select Condition">
                                @for (item of condition(i); track $index) {
                                <nz-option [nzValue]="item.key" [nzLabel]="item.label | lowercase"></nz-option>
                                }
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Field is required" class="option-input">
                            <input nz-input formControlName="value" placeholder="Enter value">
                            <button nzSize="large" nz-button (click)="removeFilter(i)"><span nz-icon nzType="delete"
                                    nzTheme="outline"></span></button>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <nz-form-item>
                <nz-form-control [nzXs]="{ span: 24, offset: 0 }">
                    <button type="button" nz-button nzType="dashed" class="add-button" (click)="addFilter()">
                        <span nz-icon nzType="plus"></span>
                        Add Filter
                    </button>
                </nz-form-control>
            </nz-form-item>

            <div class="form-button">
                <button [disabled]="filterConditions.length < 1" nz-button nzType="default" type="button"
                    (click)="clearAll()">
                    Clear Filters
                </button>
                <button [disabled]="!filtersForm.valid" nz-button nzType="primary" type="button" class="primary"
                    (click)="ApplyFilters()">
                    Apply Filter
                </button>
            </div>
        </form>
    </ng-container>
</nz-drawer>

<app-form-responses-table [responses]="response$ | async" [formId]="formId" />