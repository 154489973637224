import { ResolveFn } from '@angular/router';
import { IFormResponse, IPublicGenericFormResponse } from '../models/forms';
import { HttpService } from '../services/http.service';
import { inject } from '@angular/core';
import { catchError, of, tap } from 'rxjs';

export const genericPublicFormsResolver: ResolveFn<IPublicGenericFormResponse | null> = (route, state) => {
  const httpService = inject(HttpService)

  const formId = route.paramMap.get('formId');

  if (formId) {
    return httpService.getGenericFormPublic(formId).pipe(
      catchError(error => {
        console.error('Error loading QR Data:', error);
        // redirect to QR could not be loaded
        return of(null);
      }))
  } else {
    return of(null);
  }

};
