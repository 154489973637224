import { Component, inject } from '@angular/core';
import { HeaderComponent } from '../../ui-components/header/header.component';
import { FormService } from '../../services/form.service';
import { AddEditQrResponseComponent } from '../../ui-components/add-edit-qr-response/add-edit-qr-response.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-qr-response-create',
  standalone: true,
  imports: [HeaderComponent, AddEditQrResponseComponent, CommonModule],
  templateUrl: './qr-response-create.component.html',
  styleUrl: './qr-response-create.component.scss'
})
export class QrResponseCreateComponent {

  formService = inject(FormService);
  form$ = this.formService.formSubject$;
}
