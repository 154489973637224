<h3 nz-typography class="text-center text-primary title">Update Field</h3>
<h5 nz-typography class="text-center  title">{{fieldData.fieldType === fieldType.Section ? 'Section Title' :
    fieldData.fieldType }}</h5>
<form nz-form class="form" [formGroup]="updateForm" nz-form nzLayout="vertical">

    <nz-form-item>
        <nz-form-label nzRequired>Label</nz-form-label>
        <nz-form-control nzErrorTip="Please enter a valid label">
            <input nz-input formControlName="label" type="text" placeholder="Field Label" />
        </nz-form-control>
    </nz-form-item>

    @if(fieldData.fieldType !== fieldType.Section) {
    <nz-form-item>
        <nz-form-label>placeholder</nz-form-label>
        <nz-form-control nzErrorTip="Please enter a valid placeholder">
            <input nz-input formControlName="placeHolder" type="text" placeholder="Field placeholder" />
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label>Tooltip</nz-form-label>
        <nz-form-control nzErrorTip="Please enter a valid tooltip">
            <input nz-input formControlName="toolTip" type="text" placeholder="Field tooltip" />
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label>Disabled</nz-form-label>
        <nz-form-control nzErrorTip="Please enter a valid tooltip">
            <label class="regular-label" nz-checkbox formControlName="disabled">Field is disabled</label> `
        </nz-form-control>
    </nz-form-item>
    }

    @if (showOptions) {
    <nz-form-label nzRequired="true">Options</nz-form-label>
    <div formArrayName="options">
        <div *ngFor="let field of options.controls; let i = index">
            <nz-form-item>
                <nz-form-control nzErrorTip="Option is required" class="option-input">
                    <input nz-input [formControlName]=" i" placeholder="Enter option">
                    <button nzSize="large" nz-button (click)="removeOption(i)"><span nz-icon nzType="delete"
                            nzTheme="outline"></span></button>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <nz-form-item>
        <nz-form-control [nzXs]="{ span: 24, offset: 0 }">
            <button type="button" nz-button nzType="dashed" class="add-button" (click)="addOption()">
                <span nz-icon nzType="plus"></span>
                Add Option
            </button>
        </nz-form-control>
    </nz-form-item>
    }
</form>


<form nz-form class="form" [formGroup]="validationRules" nz-form nzLayout="vertical" class="validation-form">
    @if (fieldData.fieldType !== fieldType.Section) {
    <label>Validations</label>
    }
    @if (validationList.includes("required")) {
    <nz-form-control>
        <label class="regular-label" nz-checkbox formControlName="required">Field is required</label> `
    </nz-form-control>
    }


    @if (validationList.includes("minlength")) {
    <div class="valid-flex">
        <nz-form-control>
            <label class="regular-label" nz-checkbox [formControlName]="'minlength'+'-check'"
                (ngModelChange)="modifyValidation($event,'minlength')">Min Length</label>
        </nz-form-control>
        <nz-form-control nzErrorTip="Please enter a valid min length">
            <nz-input-number nz-input formControlName="minlength" [nzMin]="0" [nzStep]="1" placeholder="Minlength" />
        </nz-form-control>
    </div>
    }

    @if (validationList.includes("maxlength")) {
    <div class="valid-flex">
        <nz-form-control>
            <label class="regular-label" nz-checkbox [formControlName]="'maxlength'+'-check'"
                (ngModelChange)="modifyValidation($event,'maxlength')">Max Length</label>
        </nz-form-control>
        <nz-form-control nzErrorTip="Please enter a valid max length">
            <nz-input-number nz-input formControlName="maxlength" [nzMin]="0" [nzStep]="1" placeholder="maxlength" />
        </nz-form-control>
    </div>
    }


    @if (validationList.includes("minvalue")) {
    <div class="valid-flex">
        <nz-form-control>
            <label class="regular-label" nz-checkbox [formControlName]="'minvalue'+'-check'"
                (ngModelChange)="modifyValidation($event,'minvalue')">Min Value</label>
        </nz-form-control>
        <nz-form-control nzErrorTip="Please enter a valid min value">
            <nz-input-number nz-input formControlName="minvalue" placeholder="minvalue" />
        </nz-form-control>
    </div>
    }

    @if (validationList.includes("maxvalue")) {
    <div class="valid-flex">
        <nz-form-control>
            <label class="regular-label" nz-checkbox [formControlName]="'maxvalue'+'-check'"
                (ngModelChange)="modifyValidation($event,'maxvalue')">Max Value</label>
        </nz-form-control>
        <nz-form-control nzErrorTip="Please enter a valid max value">
            <nz-input-number nz-input formControlName="maxvalue" placeholder="maxvalue" />
        </nz-form-control>
    </div>
    }

    @if (validationList.includes("regex")) {
    <div class="valid-flex">
        <nz-form-control>
            <label class="regular-label" nz-checkbox [formControlName]="'regex'+'-check'"
                (ngModelChange)="modifyValidation($event,'regex')">Regex</label>
        </nz-form-control>
        <nz-form-control nzErrorTip="Please enter a valid regex">
            <input nz-input formControlName="regex" placeholder="regex" />
        </nz-form-control>
    </div>
    }

    @if (validationList.includes("dateformat")) {
    <div class="valid-flex">
        <nz-form-control>
            <label class="regular-label" nz-checkbox [formControlName]="'dateformat'+'-check'"
                (ngModelChange)="modifyValidation($event,'dateformat')">Date Format</label>
        </nz-form-control>
        <nz-form-control nzErrorTip="Please enter a valid dateformat">
            <nz-select formControlName="dateformat"
                nzPlaceHolder="Date format">
                @for (option of dateFormats; track $index) {
                <nz-option [nzValue]="option" [nzLabel]="option"></nz-option>
                }
            </nz-select>
        </nz-form-control>
    </div>
    }

    @if (validationList.includes("allowdecimals")) {
    <nz-form-control>
        <label class="regular-label" nz-checkbox formControlName="allowdecimals">Allow Decimals</label>
    </nz-form-control>
    }


    @if (validationList.includes("noofdecimalpoints")) {
    <div class="valid-flex">
        <nz-form-control>
            <label class="regular-label" nz-checkbox [formControlName]="'noofdecimalpoints'+'-check'"
                (ngModelChange)="modifyValidation($event,'noofdecimalpoints')">Number of decimal places</label>
        </nz-form-control>
        <nz-form-control>
            <nz-input-number nz-input formControlName="noofdecimalpoints" [nzMin]="1" [nzStep]="1"
                placeholder="no of decimal points" />
        </nz-form-control>
    </div>
    }

    @if (validationList.includes("allownegative")) {
    <nz-form-control>
        <label class="regular-label" nz-checkbox formControlName="allownegative">Allow Negatives</label>
    </nz-form-control>
    }

    @if (validationList.includes("allowpast")) {
    <nz-form-control>
        <label class="regular-label" nz-checkbox formControlName="allowpast">Allow Past</label>
    </nz-form-control>
    }

    @if (validationList.includes("allowfuture")) {
    <nz-form-control>
        <label class="regular-label" nz-checkbox formControlName="allowfuture">Allow Future</label>
    </nz-form-control>
    }

    @if (validationList.includes("allowmultiple")) {
    <nz-form-control>
        <label class="regular-label" nz-checkbox formControlName="allowmultiple">Allow Multiple</label>
    </nz-form-control>
    }

    @if(fieldData.fieldType === fieldType.Text){
    <label>Restrictions</label>
    <nz-form-control>
        <nz-radio-group formControlName="restriction" nzName="radiogroup">
            <label class="regular-label" nz-radio [nzValue]="null">no restricions</label>
            <label class="regular-label" nz-radio nzValue="alphanumeric">Alphanumeric</label>
            <label class="regular-label" nz-radio nzValue="email">Email</label>
            <label class="regular-label" nz-radio nzValue="number">Number only</label>
        </nz-radio-group>
    </nz-form-control>
    }

</form>


<div class="form-button">
    <button [disabled]="!updateForm.valid" nz-button nzType="primary" type="button" class="primary"
        [nzLoading]="loading$ | async" (click)="updateField()">
        Save Changes
    </button>
</div>