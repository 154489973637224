<div nz-row nzJustify="center" nzAlign="middle" class="pub-form" #form>
    <div class="text-center form-header ">
        <h1 nz-typography>
            {{displayConfig?.formHeading}}
        </h1>
        <h4 nz-typography>{{displayConfig?.formSubHeading}}</h4>
    </div>
    <form nz-form nz-form nzLayout="vertical">
        <div nz-row [nzGutter]="36">
            <ng-container *ngFor="let field of formfields">

                <div nz-col [nzSpan]="24">
                    @if (['section'].includes(field.fieldType)) {
                    <h5 nz-typography>{{field.fieldLabel}}</h5>
                    }
                    @else {
                    <nz-form-item>
                        <nz-form-label [nzFor]="field.fieldId" [nzTooltipTitle]="field.toolTip">
                            {{ field.fieldLabel }}
                        </nz-form-label>
                        <nz-form-control>

                            <input *ngIf="field.fieldType === 'text'" nz-input [placeholder]="field.placeHolder"
                                [id]="field.fieldId" />

                            <nz-radio-group *ngIf="field.fieldType === 'radio'">
                                @for (option of field.options; track $index) {
                                <label nz-radio [nzValue]="option">{{ option }}</label>
                                }
                            </nz-radio-group>

                            <nz-input-number *ngIf="field.fieldType === 'number'" [nzSize]="'large'"
                                style="width: 100%"></nz-input-number>

                            <nz-select *ngIf="field.fieldType === 'select'" [nzDisabled]="field.isDisabled"
                                [nzPlaceHolder]="field.placeHolder">
                                @for (option of field.options; track $index) {
                                <nz-option [nzValue]="option" [nzLabel]="option"></nz-option>
                                }
                            </nz-select>

                            <nz-select class="multi" *ngIf="field.fieldType === 'multiselect'"
                                [nzDisabled]="field.isDisabled" [nzPlaceHolder]="field.placeHolder" nzMode="multiple"
                                [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder">
                                @for (option of field.options; track $index) {
                                <nz-option [nzValue]="option" [nzLabel]="option"></nz-option>
                                }
                            </nz-select>

                            <textarea *ngIf="field.fieldType === 'textarea'" nz-input [placeholder]="field.placeHolder"
                                [nzAutosize]="{ minRows: 3, maxRows: 5 }" [id]="field.fieldId"></textarea>

                            <input *ngIf="field.fieldType === 'calendar'" nz-input [placeholder]="field.placeHolder"
                                [id]="field.fieldId" />

                            <ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length }}
                                more</ng-template>
                            <!-- Add more field types here as needed -->
                        </nz-form-control>
                    </nz-form-item>
                    }
                </div>
            </ng-container>
        </div>

        <div class="form-buttons">
            <button nz-button nzType="primary" type="button"
                class="submit">{{displayConfig?.submitButtonLabel}}</button>
        </div>

    </form>
</div>