import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { IDisplayConfig, IFormField } from '../../models/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-form-preview',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NzFormModule, NzInputModule, NzGridModule, NzUploadModule, NzIconModule, NzButtonModule, NzModalModule, NzSelectModule, NzTypographyModule, NzInputNumberModule, NzRadioModule],
  templateUrl: './form-preview.component.html',
  styleUrl: './form-preview.component.scss'
})
export class FormPreviewComponent {

  @Input() formfields: IFormField[] = []
  @ViewChild('form', { static: false }) formElem: any;

  @Input() displayConfig: IDisplayConfig | null = null;

  ngAfterViewInit(): void {
    this.setStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["displayConfig"]) {
      this.setStyles();
    }
  }

  setStyles() {
    if (!this.formElem) return;
    this.formElem.nativeElement.style.setProperty('--bg-color', this.displayConfig?.backgroundColor || '#fff');
    this.formElem.nativeElement.style.setProperty('--text-color', this.displayConfig?.textColor || '#434343');
    this.formElem.nativeElement.style.setProperty('--primary-color', this.displayConfig?.primaryColor || '#003a8c');
    this.formElem.nativeElement.style.setProperty('--label-color', this.displayConfig?.labelColor || '#595959');
    this.formElem.nativeElement.style.setProperty('--primary-font', this.displayConfig?.primaryFont ? `${this.displayConfig?.primaryFont}` : '"Archivo", sans-serif');
    this.formElem.nativeElement.style.setProperty('--display-font', this.displayConfig?.displayFont ? `${this.displayConfig?.displayFont}` : '"DM Serif Display", serif');

  }

}
