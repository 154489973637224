<div class="cover" #form>

    <div class="links">
        <a nz-button nzSize="large" nzType="link" (click)="downloadVCard()">
            <span nz-icon nzType="download" style="font-size:24px" nzTheme="outline"></span>
        </a>
    </div>

    <div class="header">
        <nz-avatar nzIcon="user" [nzSrc]="(getImagePath('Photo') | s3Url | async) ?? ''" [nzSize]="140"
            class="avatar" />
        <h1>{{getFieldValueByKey('Name')}}</h1>
        <p>{{getFieldValueByKey('Title')}}, {{getFieldValueByKey("Company Name")}}</p>
    </div>

    <div class="actions">
        <a [href]="phonehref()"><span nz-icon nzType="phone" style="font-size:16px" nzTheme="outline"></span> Call</a>
        <a [href]="mailtoRef()"><span nz-icon nzType="mail" style="font-size:16px" nzTheme="outline"></span>Email</a>
    </div>

    <div class="content">
        <p>{{getFieldValueByKey('Description')}}</p>

        @for (item of listOrder; track $index) {
        @if (getFieldValueByKey(item.key) !== '') {
        <nz-divider />
        <div class="content-section">
            <span nz-icon [nzType]="item.icon" style="font-size:36px" nzTheme="outline" class="icons"></span>
            <div>
                <pre>{{getFieldValueByKey(item.key)}}</pre>
                <p>{{item.key}}</p>
            </div>
        </div>
        }
        }

        @if (getFieldValueByKey("FBURL") || getFieldValueByKey("LinkedInURL") || getFieldValueByKey("XURL")) {
        <nz-divider></nz-divider>
        <div class="sm">

            @for (item of socialMedia; track $index) {
            @if (getFieldValueByKey(item.key) !== '') {
            <a [href]="getFieldValueByKey(item.key)" target="_blank">
                <img [src]="item.img" alt="">
            </a>
            }
            }
        </div>
        }


    </div>


</div>