import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from '../../environments/environment';
import { IDateFormatResponse, IDisplayConfig, IFieldDeleteRequest, IFieldOrderRequest, IFieldTypesResponse, IFieldUpdateRequest, IFilterMasterResponse, IFormFieldRequest, IFormResponse, IPublicGenericFormResponse } from '../models/forms';
import { IQRAPIResponse, IResponseAPIResponse, IResponsesRequest } from '../models/qr';
import { IAPIResponse } from '../models/base';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private formsAPIUrl = `${env.apiBaseUrl}/forms-service/forms`;
  private trashAPIUrl = `${env.apiBaseUrl}/forms-service/trash`;
  private publicAPIUrl = `${env.apiBaseUrl}/forms-service/public`;
  private masterAPIUrl = `${env.apiBaseUrl}/forms-service/forms/master`;

  // ​/forms-service​/forms​/response​/load

  constructor(private http: HttpClient) { }

  getForm(id: string) {
    const url = `${this.formsAPIUrl}/load/${id}`;
    return this.http.get<IFormResponse>(url)
  }

  updateDisplayConfig(formId: string, config: IDisplayConfig) {
    const url = `${this.formsAPIUrl}/config/updatedisplayconfig`
    return this.http.put<any>(url, { ...config, formId });
  }

  updateFormStatus(payload: { formId: string, isEnabled: boolean }) {
    const url = `${this.formsAPIUrl}/updatestatus`
    return this.http.put<any>(url, payload);
  }

  addResponse(data: FormData, formId: string) {

    const url = `${this.formsAPIUrl}/response/${formId}/submit`
    return this.http.post<any>(url, data);
  }

  updateResponse(data: FormData, formId: string, responseId: string) {

    const url = `${this.formsAPIUrl}/response/${formId}/update/${responseId}`
    return this.http.patch<any>(url, data);
  }

  getResponses(params: IResponsesRequest) {
    const url = `${this.formsAPIUrl}/response/load`
    return this.http.post<IResponseAPIResponse>(url, params)
  }

  generateQR(req: { formId: string, responseId: string }) {
    const url = `${this.formsAPIUrl}/response/qr/generate`
    return this.http.post<IAPIResponse<{}>>(url, req)
  }

  deleteResponse(req: { formId: string, responseId: string }) {
    const url = `${this.formsAPIUrl}/response/delete`
    return this.http.delete<IAPIResponse<{}>>(url, { body: req })
  }

  getQRpublicAPI(formId: string, responseId: string) {
    const url = `${this.publicAPIUrl}/qrcode/${formId}/${responseId}`
    return this.http.get<IQRAPIResponse>(url);
  }

  getGenericFormPublic(formId: string) {
    const url = `${this.publicAPIUrl}/generic/${formId}`
    return this.http.get<IPublicGenericFormResponse>(url);
  }

  submitPublicGenericFormResponse(formId: string, data: FormData) {
    const url = `${this.publicAPIUrl}/generic/${formId}/submit`
    return this.http.post<any>(url, data);
  }

  getTrashedResponses(formId: string) {
    const url = `${this.trashAPIUrl}/forms/responses/load/${formId}`
    return this.http.get<IResponseAPIResponse>(url)
  }

  restoreResponse(req: { formId: string, responseId: string }) {
    const url = `${this.trashAPIUrl}/forms/responses/restore`
    return this.http.patch<IAPIResponse<{}>>(url, req)
  }

  deleteTrashResponse(req: { formId: string, responseId: string }) {
    const url = `${this.trashAPIUrl}/forms/responses/delete`
    return this.http.delete<IAPIResponse<{}>>(url, { body: req })
  }
  emptyTrash(req: { formId: string }) {
    const url = `${this.trashAPIUrl}/forms/responses/emptytrash`
    return this.http.delete<IAPIResponse<{}>>(url, { body: req })
  }

  getFieldsMasterData() {
    const url = `${this.formsAPIUrl}/master/fieldtypes`;
    return this.http.get<IFieldTypesResponse>(url);
  }

  getDateFormats() {
    const url = `${this.formsAPIUrl}/master/dateformats`;
    return this.http.get<IDateFormatResponse>(url);
  }

  addFormField(field: IFormFieldRequest) {
    const url = `${this.formsAPIUrl}/fields/add`;
    return this.http.post<IAPIResponse<{}>>(url, field);
  }

  changeFieldOrder(req: IFieldOrderRequest) {
    const url = `${this.formsAPIUrl}/fields/reorder`;
    return this.http.put<IAPIResponse<{}>>(url, req);
  }

  updateField(req: IFieldUpdateRequest) {
    const url = `${this.formsAPIUrl}/fields/edit`;
    return this.http.put<IAPIResponse<{}>>(url, req);
  }

  fieldDelete(req: IFieldDeleteRequest) {
    const url = `${this.formsAPIUrl}/fields/delete`;
    return this.http.delete<IAPIResponse<{}>>(url, {
      body: req
    });
  }

  getFilterMaster() {
    const url = `${this.masterAPIUrl}/datatypes`;
    return this.http.get<IFilterMasterResponse>(url)
  }

}
