import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFormResponse } from '../models/qr';
import { HttpService } from './http.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ResponsesService } from './responses.service';

interface responseData {
  data: IFormResponse[],
  item: IFormResponse | null
  loading: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TrashService {

  constructor(private httpService: HttpService, private message: NzMessageService, private responsesService: ResponsesService) { }

  isTrashResponsesCleared$ = new BehaviorSubject<boolean>(false);
  data$ = new BehaviorSubject<responseData>({
    data: [],
    item: null,
    loading: false
  })

  get data() {
    return this.data$.value;
  }

  getAllResponses(formId: string) {
    this.data$.next({ ...this.data, loading: true });
    this.httpService.getTrashedResponses(formId).subscribe({
      next: res => {
        this.data$.next({ ...this.data, data: res.data, loading: false })
      }
    })
  }

  restoreResponse(formId: string, responseId: string) {
    this.data$.next({ ...this.data, loading: true });
    this.httpService.restoreResponse({ formId, responseId }).subscribe({
      next: (res) => {
        this.getAllResponses(formId)
        this.message.success("Item restored succesfully");
      },
      error: () => { }
    })
  }

  deleteResponse(formId: string, responseId: string) {
    this.data$.next({ ...this.data, loading: true });
    this.httpService.deleteTrashResponse({ formId, responseId }).subscribe({
      next: (res) => {
        this.getAllResponses(formId)
        this.message.success("Item Deleted succesfully");
      },
      error: () => { }
    })
  }

  emptyFormTrash(formId: string) {
    this.data$.next({ ...this.data, loading: true });
    this.httpService.emptyTrash({ formId }).subscribe({
      next: (res) => {
        this.data$.next({ ...this.data, data: [], loading: false })
        this.isTrashResponsesCleared$.next(true);
        this.message.success("Trash Items Cleared succesfully");
      },
      error: () => { }
    })
  }
}
