import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormService } from '../../services/form.service';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { env } from '../../../environments/environment';

@Component({
  selector: 'app-generic-form-public-switch',
  standalone: true,
  imports: [NzSwitchModule, CommonModule, FormsModule, NzButtonComponent, NzIconModule],
  templateUrl: './generic-form-public-switch.component.html',
  styleUrl: './generic-form-public-switch.component.scss'
})
export class GenericFormPublicSwitchComponent {

  @Input() formId!: string;
  @Input() isEnabled = false;

  formService = inject(FormService);

  onSwitchChange(ev: boolean) {
    this.formService.updateFormStatus(this.formId, ev);
  }

  publicUrl() {
    return `${env.formsAppUrl}/forms/generic/${this.formId}`
  }
}
