import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { MessageListenerService } from './services/message-listener.service';
import { CommonModule } from '@angular/common';
import { env } from '../environments/environment';
import { LayoutComponent } from './ui-components/layout/layout.component';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { S3Service } from './services/s3.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, LayoutComponent, NzMessageModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  listener = inject(MessageListenerService);
  s3Service = inject(S3Service);

  title = 'nexus-forms';
  canLoadRouter$ = this.listener.canLoadPages$;

  isPublic = this.listener.isPublic();


  signalReady() {
    // window.opener?.postMessage('ready', env.consoleAppUrl);
  }

  ngOnInit(): void {
    this.signalReady()
    this.s3Service.initializeClient();
  }
}
