<app-header>
    <div class="header-right">
        @if ((form$ |async)?.formType === formTypes.GENERIC) {
        <app-generic-form-public-switch [formId]="formId" [isEnabled]="(form$ |async)?.isPublic ?? false" />
        }

        <app-basic-style-modal />

        @if ((form$ |async)?.formType === formTypes.QRCODE) {
        <button nz-button nzType="primary" [routerLink]="[ 'create-qr-response']">
            <span nz-icon nzType="file-add"></span>
            Create dynamic QR
        </button>
        }
    </div>
</app-header>

@if ((form$ |async)?.formType === formTypes.QRCODE) {
<nz-skeleton [nzActive]="true" [nzLoading]="(listLoading$ | async) || false">

    <div nz-row [nzGutter]="[24, 24]">
        @for (item of responses$ | async; track $index){
        <div nz-col [nzSpan]="6">
            <app-qr-list-card [response]="item" />
        </div>
        }
    </div>
</nz-skeleton> ̰
}

@if ((form$ |async)?.formType === formTypes.GENERIC) {
<app-generic-builder />
}