import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FormService } from '../services/form.service';
import { IForm } from '../models/forms';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs';

export const formResolver: ResolveFn<IForm | null> = (route, state) => {

  const formService = inject(FormService);

  const formId = route.paramMap.get('formId');
  if (formId && !!!formService.formSubject$.value) {
    return formService.resolveForm(formId).pipe(
      catchError(error => {
        console.error('Error loading form:', error);
        // redirect to form could not be loaded
        return of(null);
      }))
  } else {
    return of(null);
  }
};
