import { ResolveFn } from '@angular/router';
import { IQRAPIResponse } from '../models/qr';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { inject } from '@angular/core';
import { HttpService } from '../services/http.service';

export const qrDataResolver: ResolveFn<IQRAPIResponse | null> = (route, state) => {

  const httpService = inject(HttpService)

  const formId = route.paramMap.get('formId');
  const responseId = route.paramMap.get('responseId');

  if (formId && responseId) {
    return httpService.getQRpublicAPI(formId, responseId).pipe(
      catchError(error => {
        console.error('Error loading QR Data:', error);
        // redirect to QR could not be loaded
        return of(null);
      }))
  } else {
    return of(null);
  }

};
