<ul nz-menu nzMode="inline">
    @if ((formType$ |async) === "qrcode") {
    <li nz-menu-item nzMatchRouter [routerLink]="['form', formId$ |async]">
        <span nz-icon nzType="qrcode"></span>
        <span>QR Codes</span>
    </li>
    }
    @else if ((formType$ |async) === "generic") {

    <li nz-submenu nzTitle="Form" nzIcon="form">
        <ul>
            <li nz-menu-item nzMatchRouter [nzMatchRouterExact]="true" [routerLink]="['form', formId$ |async]">
                <span nz-icon nzType="build"></span>
                <span>Builder</span>
            </li>
            <li nz-menu-item nzMatchRouter [nzMatchRouterExact]="true"
                [routerLink]="['form', formId$ |async, 'responses']">
                <span nz-icon nzType="unordered-list" nzTheme="outline"></span>
                <span>Responses</span>
            </li>
        </ul>
    </li>

    }

    <li nz-menu-item nzMatchRouter routerLink="analytics">
        <span nz-icon nzType="fund-view" nzTheme="outline"></span>
        <span>Analytics</span>
    </li>
    <li nz-menu-item nzMatchRouter [routerLink]="['trash', formId$ | async]">
        <span nz-icon nzType="rest" nzTheme="outline"></span>
        <span>Trash</span>
    </li>
</ul>