<app-header [showBack]="true">
    <div class="action-items">
        <ng-container *ngIf="(form$ | async) as form">
            <ng-container *ngIf="(response$ | async) as resp">
                @if(resp.recordQRInfo.qrEnabled){

                <span>
                    <b>{{resp.recordQRInfo.noofScans}}</b> Scans
                </span>

                <span>
                    <b>last scaned: </b>{{(resp.recordQRInfo.lastScannedAt | dateAgo) ?? '-' }}
                </span>

                <a nz-button nzType="link" (click)="qrPreview = true"><span nz-icon nzType="qrcode"
                        nzTheme="outline"></span>QR Code</a>
                }
                <a nz-button nzType="link" (click)="openPreview()"><span nz-icon nzType="eye"
                        nzTheme="outline"></span>preview</a>

                <nz-drawer [nzClosable]="false" [nzVisible]="preview" nzPlacement="right" (nzOnClose)="closePreview()"
                    [nzWidth]="400">
                    <ng-container *nzDrawerContent>
                        <app-contact-card [form]="form.formFields" [response]="resp.formResponse"
                            [displayConfig]="resp.displayConfig" />
                    </ng-container>
                </nz-drawer>

                <nz-modal [nzVisible]="qrPreview" [nzContent]="modalContent" [nzFooter]="null"
                    (nzOnCancel)="qrPreview = false">
                    <ng-template #modalContent>
                        <img [src]="(resp.recordQRInfo.qrLocation ?? '' | s3Url | async) ?? ''"
                            [ngStyle]="{ width: '100%' }" />
                    </ng-template>
                </nz-modal>

            </ng-container>
        </ng-container>
    </div>
</app-header>
<ng-container *ngIf="(form$ | async) as form">
    <ng-container *ngIf="(response$ | async) as response">

        <app-add-edit-qr-response [form]="form" [response]="response" [editForm]="true"
            *ngIf="form && response"></app-add-edit-qr-response>
    </ng-container>
</ng-container>