export const errorMessages = {
    required: 'This field is required.',
    email: 'Please enter a valid email address.',
    minLength: (requiredLength: number) => `Minimum length should be ${requiredLength} characters.`,
    maxLength: (requiredLength: number) => `Maximum length should be ${requiredLength} characters.`,
    minValue: (minValue: number) => `Minimum value should be ${minValue}.`,
    maxValue: (maxValue: number) => `Maximum value should be ${maxValue}.`,
    regex: 'Value does not match pattern',
    alphanumeric: 'Only alphabets and numbers allowed',
    number: 'Only numbers allowed',
};