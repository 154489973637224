import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { HeaderComponent } from '../../ui-components/header/header.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { QrListCardComponent } from '../../ui-components/qr-list-card/qr-list-card.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { BasicStyleModalComponent } from '../../ui-components/basic-style-modal/basic-style-modal.component';
import { FormService } from '../../services/form.service';
import { FormTypes } from '../../models/forms';
import { TrashService } from '../../services/trash.service';
import { Subject, combineLatestWith, map, takeUntil } from 'rxjs';
import { QrTrashResponseCardComponent } from '../../ui-components/qr-trash-response-card/qr-trash-response-card.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { FormTrashTableComponent } from '../../ui-components/form-trash-table/form-trash-table.component';

@Component({
  selector: 'app-trash',
  standalone: true,
  imports: [CommonModule, HeaderComponent, NzButtonModule, NzModalModule, NzTypographyModule, RouterModule, NzGridModule,
    QrListCardComponent, QrTrashResponseCardComponent, NzSkeletonModule, BasicStyleModalComponent, NzIconModule, FormTrashTableComponent
  ],
  templateUrl: './trash.component.html',
  styleUrl: './trash.component.scss'
})
export class TrashComponent {

  formService = inject(FormService);
  trashService = inject(TrashService);
  route = inject(ActivatedRoute);

  formId: string = '';
  form$ = this.formService.formSubject$;
  listLoading$ = this.trashService.data$.pipe(map(x => x.loading))
  isTrashCleared$ = this.trashService.isTrashResponsesCleared$;
  private unsubscribe$ = new Subject<void>();

  formTypes = FormTypes
  isVisible = false;

  responses$ = this.trashService.data$.pipe(
    map(x => x.data),
    combineLatestWith(this.formService.formSubject$),
    map(([e1, e2]) => {
      if (!e2) return null;
      const res = e1.map(response => ({
        ...response,
        formResponse: response.formResponse.map(field => ({ ...field, key: e2.formFields.find(x => x.fieldId === field.fieldId)?.fieldLabel }))
      }))
      return res
    }),
  )

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel() {
    this.isVisible = false;
  }

  deleteFormConfirm() {
    this.trashService.emptyFormTrash(this.formId)
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.formId = params['formId'];
        this.trashService.getAllResponses(this.formId);
      });

    this.isTrashCleared$.subscribe(isCleared => {
      if (isCleared) this.isVisible = false;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
