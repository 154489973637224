import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormService } from '../../services/form.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../ui-components/header/header.component';
import { FormTypes, IFormResponse } from '../../models/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ResponsesService } from '../../services/responses.service';
import { Subscription, combineLatestWith, map, merge, tap } from 'rxjs';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { QrListCardComponent } from '../../ui-components/qr-list-card/qr-list-card.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { BasicStyleModalComponent } from '../../ui-components/basic-style-modal/basic-style-modal.component';
import { GenericBuilderComponent } from '../generic-builder/generic-builder.component';
import { GenericFormPublicSwitchComponent } from '../../ui-components/generic-form-public-switch/generic-form-public-switch.component';




@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, HeaderComponent, NzButtonModule, RouterModule, NzGridModule, QrListCardComponent, NzSkeletonModule, BasicStyleModalComponent, GenericBuilderComponent, GenericFormPublicSwitchComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, OnDestroy {

  formService = inject(FormService);
  responseService = inject(ResponsesService)
  route = inject(ActivatedRoute);

  formId: string = '';
  form$ = this.formService.formSubject$;
  listLoading$ = this.responseService.data$.pipe(map(x => x.loading))
  subscriptions: Subscription[] = []


  responses$ = this.responseService.data$.pipe(
    map(x => x.data),
    combineLatestWith(this.formService.formSubject$),
    map(([e1, e2]) => {
      if (!e2) return null;
      const res = e1.map(response => ({
        ...response,
        formResponse: response.formResponse.map(field => ({ ...field, key: e2.formFields.find(x => x.fieldId === field.fieldId)?.fieldLabel }))
      }))
      return res
    }),
  )


  formTypes = FormTypes

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.formId = params['formId'];
        this.formService.getForm(this.formId);
        this.responseService.getAllResponses(this.formId);
      })
    )

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe())
  }


}
