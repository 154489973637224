<nz-card class="qr-card">

    @if(response.recordQRInfo.qrEnabled){
    <span class="qr-img" nz-icon nzType="qrcode" nzTheme="outline"></span>
    }

    <div class="content">
        <div class="header">
            <nz-avatar class="avatar" [nzSize]="40" nzIcon="user"
                [nzSrc]="(getImagePath('Photo', response.formResponse ) | s3Url | async) ?? ''"></nz-avatar>
            <h4 nz-typography nzEllipsis>{{getFieldValueByKey('Name', response.formResponse)}}</h4>
            <p nz-typography nzEllipsis>{{getFieldValueByKey('Title', response.formResponse)}}</p>
        </div>

        <p class="desc" nz-typography nzEllipsis [attr.title]="getFieldValueByKey('Description', response.formResponse)"
            [nzEllipsisRows]="3">
            {{getFieldValueByKey('Description', response.formResponse)}}</p>



        <div class="info">
            <div class="info-left">
                <p>{{response.recordQRInfo.noofScans ?? 0}} scans</p>
                <p> Last Scan: {{(response.recordQRInfo.lastScannedAt | dateAgo) ?? '-'}}</p>
            </div>
            <div class="info-right">
                <p><b>Created On: </b> {{ response.createdAt | dateAgo}}</p>
                <p><b>Last updated on: </b> {{ response.updatedAt | dateAgo}}</p>
            </div>
        </div>

        <div class="actions">
            <nz-space [nzSize]="16">
                @if(response.recordQRInfo.qrEnabled) {
                <a *nzSpaceItem nz-button nzType="link" (click)="previewVisible = true">View QR Code</a>
                }
                @else {
                <a *nzSpaceItem nz-button nzType="link" nz-popconfirm
                    nzPopconfirmTitle="Please confirm to generate QR Code"
                    (nzOnConfirm)="generateQR(response.formId, response.id)">Generate QR Code</a>
                }
                <a *nzSpaceItem nz-button nzType="link"
                    [routerLink]="['/form', response.formId, 'edit-qr-response', response.id]">Edit</a>
                <a *nzSpaceItem nz-button nz-dropdown [nzDropdownMenu]="menu" style="border: none; padding: 0">
                    <span nz-icon nzType="ellipsis" style="font-size: 20px;" nzTheme="outline"></span>
                </a>
            </nz-space>
        </div>
    </div>
</nz-card>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item nzDanger nz-popconfirm nzPopconfirmTitle="Please confirm to Delete QR Code"
            (nzOnConfirm)="deleteResponse(response.id, response.formId)">Delete</li>
    </ul>
</nz-dropdown-menu>

<nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
    (nzOnCancel)="previewVisible = false">
    <ng-template #modalContent>
        <img [src]="(response.recordQRInfo.qrLocation ?? '' | s3Url | async) ?? ''" [ngStyle]="{ width: '100%' }" />
    </ng-template>
</nz-modal>