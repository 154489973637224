import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject, tap } from 'rxjs';
import { ICondition, IFormResponse, IResponsesRequest } from '../models/qr';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IFilterResponse } from '../models/forms';

interface responseData {
  data: IFormResponse[],
  item: IFormResponse | null
  loading: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ResponsesService {

  constructor(private httpService: HttpService, private message: NzMessageService) { }


  data$ = new BehaviorSubject<responseData>({
    data: [],
    item: null,
    loading: false
  })

  filtersMasterData$ = new BehaviorSubject<IFilterResponse[] | null>(null)

  get data() {
    return this.data$.value;
  }

  resolveResponses(formId: string) {
    return this.httpService.getResponses(
      {
        conditions: [],
        formId,
        limit: 20,
        pageNo: 0,
        searchString: '',
        sortOrder: 'DESC'
      }
    ).pipe(tap(form => {
      this.data$.next({ ...this.data, data: form.data, loading: false });
    }));
  }

  getAllResponses(formId: string) {
    this.data$.next({ ...this.data, loading: true });
    const responseParams: IResponsesRequest = {
      conditions: [],
      formId,
      limit: 0,
      pageNo: 0,
      searchString: '',
      sortOrder: 'DESC'
    }
    this.httpService.getResponses(responseParams).subscribe({
      next: res => {
        this.data$.next({ ...this.data, data: res.data, loading: false })
      }
    })
  }

  getAllResponseWithCondition(formId: string, searchString: string, conditions: ICondition[]) {
    this.data$.next({ ...this.data, loading: true });
    const responseParams: IResponsesRequest = {
      conditions,
      formId,
      limit: 0,
      pageNo: 0,
      searchString,
      sortOrder: 'DESC'
    }
    this.httpService.getResponses(responseParams).subscribe({
      next: res => {
        this.data$.next({ ...this.data, data: res.data, loading: false })
      }
    })
  }

  generateQR(formId: string, responseId: string) {
    this.data$.next({ ...this.data, loading: true });
    this.httpService.generateQR({ formId, responseId }).subscribe({
      next: (res) => {
        this.getAllResponses(formId)
        this.message.success("QR Code generated Succesfully");
      },
      error: () => { }
    })
  }

  deleteResponse(formId: string, responseId: string) {
    this.data$.next({ ...this.data, loading: true });
    this.httpService.deleteResponse({ formId, responseId }).subscribe({
      next: (res) => {
        const currentValue = this.data$.getValue()
        const data = currentValue.data.filter(x => x.id !== responseId)
        this.data$.next({
          data,
          loading: false,
          item: null
        })
        this.message.success("Item deleted succesfully");
      },
      error: () => { }
    })
  }

  getFilterMasterData() {
    this.httpService.getFilterMaster().subscribe({
      next: (res) => {
        this.filtersMasterData$.next(res.data);
      }
    })
  }


}
