import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { QrResponseCreateComponent } from './pages/qr-response-create/qr-response-create.component';
import { formResolver } from './resolvers/form.resolver';
import { PublicQrComponent } from './pages/public-qr/public-qr.component';
import { qrDataResolver } from './resolvers/qr-data.resolver';
import { QrResponseEditComponent } from './pages/qr-response-edit/qr-response-edit.component';
import { responseResolver } from './resolvers/response.resolver';
import { PublicFormComponent } from './pages/public-form/public-form.component';
import { genericPublicFormsResolver } from './resolvers/generic-public-forms.resolver';
import { TrashComponent } from './pages/trash/trash.component';
import { GenericBuilderComponent } from './pages/generic-builder/generic-builder.component';
import { responsesResolver } from './resolvers/responses.resolver';
import { GenericResponsesComponent } from './pages/generic-responses/generic-responses.component';

export const routes: Routes = [
    {
        path: 'form/:formId',
        component: HomeComponent,
        pathMatch: 'full',
    },
    {
        path: 'form/:formId/builder',
        component: GenericBuilderComponent,
        pathMatch: 'full'
    },
    {
        path: 'form/:formId/create-qr-response',
        component: QrResponseCreateComponent,
        resolve: {
            form: formResolver
        }
    },
    {
        path: 'form/:formId/responses',
        component: GenericResponsesComponent,
        resolve: {
            form: formResolver,
            response: responsesResolver
        }
    },
    {
        path: 'form/:formId/edit-qr-response/:responseId',
        component: QrResponseEditComponent,
        resolve: {
            form: formResolver,
            response: responseResolver
        }
    },
    {
        path: 'forms/:formId/qr/:responseId',
        component: PublicQrComponent,
        resolve: {
            qrData: qrDataResolver
        }
    },
    {
        path: 'forms/generic/:formId',
        component: PublicFormComponent,
        resolve: {
            formData: genericPublicFormsResolver
        }
    },
    {
        path: 'trash/:formId',
        component: TrashComponent,
        pathMatch: 'full'
    },

];
