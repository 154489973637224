import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FieldType, IFormField, IGenericFormInfo, IValidation, ValidationRules } from '../../models/forms';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { errorMessages } from '../../utils/errors';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { FormService } from '../../services/form.service';
import { skip } from 'rxjs';

@Component({
  selector: 'app-public-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NzFormModule, NzInputModule, NzDatePickerModule, NzGridModule, NzUploadModule, NzIconModule, NzButtonModule, NzModalModule, NzSelectModule, NzTypographyModule, NzInputNumberModule, NzRadioModule],
  templateUrl: './public-form.component.html',
  styleUrl: './public-form.component.scss'
})
export class PublicFormComponent implements OnInit, AfterViewInit, OnDestroy {

  private activatedRoute = inject(ActivatedRoute);
  private formService = inject(FormService);

  formFields!: IFormField[];
  formInfo!: IGenericFormInfo
  formGroup!: FormGroup;
  loading$ = this.formService.loading$
  errors!: { [key: string]: string }

  @ViewChild('form', { static: false }) formElem: any;

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ formData }) => {

      this.formFields = formData.formFields.sort(this.comparePositions);
      this.formInfo = formData.formInfo
      this.formGroup = this.generateForm(formData.formFields)
      this.setFormValidations();

    })

    this.formService.errorList$.subscribe(errors => {
      this.errors = errors;
      Object.entries(errors).forEach(([key, val]) => {
        const control = this.formGroup.get(key);
        if (!control) return;
        control.setErrors({ customError: val })
      })
    })

    this.formService.resetForm$.pipe(skip(1)).subscribe(x => {
      if (x) {
        this.formGroup.reset();
      }
    });

  }

  ngAfterViewInit(): void {
    this.setStyles();
  }

  ngOnDestroy(): void {
    this.formService.errorList$.unsubscribe();
  }

  comparePositions(a: IFormField, b: IFormField) {
    return a.position - b.position;
  }

  generateForm(formFields: IFormField[]) {
    const formControls: { [key: string]: FormControl } = {};
    formFields.forEach(field => {
      if ([FieldType.Section].includes(field.fieldType)) return;
      let defaultValue: string | number = ''
      if (field.fieldType === 'number') defaultValue = this.numberFieldProperty('min', field.fieldId)
      formControls[field.fieldId] = new FormControl({ value: field.fieldType === 'multiselect' ? [] : defaultValue, disabled: field.isDisabled });
    })
    return new FormGroup(formControls);
  }

  isRequired(validations: IValidation[]) {
    return validations.some(validation => validation.rule === ValidationRules.REQUIRED && validation.value === 'true')
  }

  // validators

  alphanumericValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // If the control value is empty, consider it valid
      }
      const alphanumericTest = /^[a-zA-Z0-9]+$/.test(control.value);
      return alphanumericTest ? null : { 'alphanumeric': true };
    };
  }

  numberValiodator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // If the control value is empty, consider it valid
      }
      const numberTest = /^[0-9]+$/.test(control.value);
      return numberTest ? null : { 'number': true };
    };
  }

  //validators


  setFormValidations() {
    this.formFields.forEach(field => {
      const validators: Validator[] = [];
      if (field.validations.length > 0) {
        // get form control by Id
        const fc = this.formGroup.get(field.fieldId)
        field.validations.forEach(validation => {
          if (validation.rule === ValidationRules.REQUIRED && validation.value === 'true') {
            fc?.addValidators(Validators.required);
          } else if (validation.rule === ValidationRules.EMAIL && validation.value === 'true') {
            fc?.addValidators(Validators.email);
          } else if (validation.rule === ValidationRules.MIN_LENGTH) {
            fc?.addValidators(Validators.minLength(parseInt(validation.value)));
          } else if (validation.rule === ValidationRules.MAX_LENGTH) {
            fc?.addValidators(Validators.maxLength(parseInt(validation.value)));
          } else if (validation.rule === ValidationRules.MIN_VALUE) {
            fc?.addValidators(Validators.min(parseInt(validation.value)))
          } else if (validation.rule === ValidationRules.MAX_VALUE) {
            fc?.addValidators(Validators.max(parseInt(validation.value)))
          } else if (validation.rule === ValidationRules.REGEX) {
            fc?.addValidators(Validators.pattern(validation.value))
          } else if (validation.rule === ValidationRules.NUMBER) {
            fc?.addValidators(this.numberValiodator())
          } else if (validation.rule === ValidationRules.ALPHANUMERIC) {
            fc?.addValidators(this.alphanumericValidator())
          }
        });
      }
    })

    this.formGroup.updateValueAndValidity();
  }

  setStyles() {
    this.formElem.nativeElement.style.setProperty('--bg-color', this.formInfo.displayConfig.backgroundColor || '#fff');
    this.formElem.nativeElement.style.setProperty('--text-color', this.formInfo.displayConfig.textColor || '#434343');
    this.formElem.nativeElement.style.setProperty('--primary-color', this.formInfo.displayConfig.primaryColor || '#003a8c');
    this.formElem.nativeElement.style.setProperty('--label-color', this.formInfo.displayConfig.labelColor || '#595959');
    this.formElem.nativeElement.style.setProperty('--primary-font', this.formInfo.displayConfig.primaryFont || '"Archivo", sans-serif');
    this.formElem.nativeElement.style.setProperty('--display-font', this.formInfo.displayConfig.displayFont || '"DM Serif Display", serif');
  }

  getErrorMessage(fieldId: string): string {
    const control = this.formGroup.get(fieldId);
    if (!control) return ''; // or handle error if control doesn't exist
    if (control.errors) {
      for (const errorName in control.errors) {
        if (control.hasError(errorName)) {
          switch (errorName) {
            case 'required':
              return errorMessages.required;
            case 'email':
              return errorMessages.email;
            case 'alphanumeric':
              return errorMessages.alphanumeric;
            case 'number':
              return errorMessages.number;
            case 'pattern':
              return errorMessages.regex;
            case 'minlength':
              return errorMessages.minLength(control.getError('minlength')?.requiredLength);
            case 'maxlength':
              return errorMessages.maxLength(control.getError('maxlength')?.requiredLength);
            case 'min':
              return errorMessages.minValue(control.getError('min')?.min);
            case 'max':
              return errorMessages.maxValue(control.getError('max')?.max);
            case 'customError':
              return this.errors[fieldId]
            default:
              return 'Invalid field'; // Generic fallback error
          }
        }
      }
    }
    return '';
  }

  numberFieldProperty(key: string, fieldId: string) {
    const validations = this.formFields.find(x => x.fieldId === fieldId)?.validations;
    if (!validations) {
      switch (key) {
        case 'min':
          return 0
        case 'max':
          return Infinity
        default:
          return 0
      }
    };

    if (key === "min") {
      const minValidator = validations.find(x => x.rule === ValidationRules.MIN_VALUE)
      const allowNegativeValidator = validations.find(x => x.rule === ValidationRules.ALLOWNEGATIVE)

      if (!minValidator && !allowNegativeValidator) return 0;
      if (allowNegativeValidator && !minValidator) return -Infinity

      if (minValidator) return +minValidator.value;

      return 0
    }

    if (key === 'max') {
      const maxValidator = validations.find(x => x.rule === ValidationRules.MAX_VALUE)
      if (!maxValidator) return Infinity
      return +maxValidator.value
    }

    if (key === 'precision') {
      const allowdecimals = validations.find(x => x.rule === ValidationRules.ALLOWDECIMALS)
      const precision = validations.find(x => x.rule === ValidationRules.DECIMALPOINTS)

      if (!allowdecimals) return 0
      return precision?.value ? +precision?.value : 2
    }

    return 0
  }

  dateFieldProperty(key: string, fieldId: string) {
    const validations = this.formFields.find(x => x.fieldId === fieldId)?.validations;

    if (key === "dateformat") {
      const dateFormat = validations?.find(x => x.rule === ValidationRules.DATE_FORMAT);

      if (dateFormat) return dateFormat.value;  
      if (!dateFormat) return 'YYYY-MM-DD';
    }
    return 'YYYY-MM-DD';
  }

  submitForm() {
    if (this.formGroup.valid) {
      let formData = new FormData();

      Object.keys(this.formGroup.controls).forEach(key => {
        const control = this.formGroup.get(key);
        if (control) {
          formData.append(key, control.value);
        }
      });
      this.formService.submitPublicGenericForm(this.formInfo.id, formData)
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }

  }

}
