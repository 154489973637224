<button nz-button nzType="primary" (click)="showModal()"><span nz-icon nzType="format-painter"></span> Update
    style</button>

<nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()" nzCentered [nzFooter]="null">
    <ng-container *nzModalContent>
        <h5 nz-typography class="text-center text-primary title">Style cutomizer</h5>
        <form nz-form [autocomplete]="true" class="form" [formGroup]="displayConfigForm" nz-form nzLayout="vertical"
            (ngSubmit)="updateDisplayConfig()">

            <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="12">
                    <nz-form-item>
                        <nz-form-label>Background color</nz-form-label>
                        <nz-form-control>
                            <nz-color-picker formControlName="backgroundColor" nzShowText></nz-color-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="12">
                    <nz-form-item>
                        <nz-form-label>Primary Color</nz-form-label>
                        <nz-form-control>
                            <nz-color-picker formControlName="primaryColor" nzShowText></nz-color-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="12">
                    <nz-form-item>
                        <nz-form-label>Label color</nz-form-label>
                        <nz-form-control>
                            <nz-color-picker formControlName="labelColor" nzShowText></nz-color-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="12">
                    <nz-form-item>
                        <nz-form-label>Text color</nz-form-label>
                        <nz-form-control>
                            <nz-color-picker formControlName="textColor" nzShowText></nz-color-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <nz-form-item>
                <nz-form-label>Primary Font</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter a valid font">
                    <input nz-input formControlName="primaryFont" type="text" placeholder="Primary Font" />
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label>Display Font</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter a valid font">
                    <input nz-input formControlName="displayFont" type="text" placeholder="Display font" />
                </nz-form-control>
            </nz-form-item>
        </form>
        <div class="form-button">
            <button nz-button nzType="primary" class="primary" [nzLoading]="formLoading$ |async"
                (click)="updateDisplayConfig()">
                Update Style
            </button>
        </div>
    </ng-container>
</nz-modal>

<nz-drawer [nzClosable]="false" [nzVisible]="isVisible" nzPlacement="right" (nzOnClose)="handleCancel()"
    [nzWidth]="400">
    <ng-container *nzDrawerContent>
        @if ((formType$ | async) === "qrcode" ) {
        <app-contact-card [displayConfig]="displayConfig" [form]="previewQRData.formFields"
            [response]="previewQRData.formResponse" />
        }
        @else {
        <app-form-preview [displayConfig]="displayConfig" [formfields]="(formFields$ | async) || []" />
        }
    </ng-container>
</nz-drawer>