import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { FormService } from '../../services/form.service';
import { map } from 'rxjs/internal/operators/map';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NzPageHeaderModule, CommonModule, NzTypographyModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() showBack = false;

  formService = inject(FormService);
  location = inject(Location);

  title$ = this.formService.formSubject$.pipe(map(x => x?.formName));
  description$ = this.formService.formSubject$.pipe(map(x => x?.description));

  onBack() {
    this.location.back();
  }
}
