<div class="logo">
    <svg width="90" height="29" viewBox="0 0 90 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_254_2293)">
            <g class="logo-letters">
                <path
                    d="M82.3237 28.7984V18.4162H89.8745V20.673H84.979V22.4013H89.7664V24.6743H84.979V26.5416H89.8745V28.7984H82.3237Z"
                    fill="#003A8C" />
                <path
                    d="M40.6475 12.7041L36.2925 6.43094V12.7041H33.636V2.3219H36.3695L40.5394 8.29823V2.3219H43.2108V12.7041H40.6475Z"
                    fill="#003A8C" />
                <path
                    d="M46.5308 12.7041V2.3219H54.0815V4.57868H49.186V6.30696H53.9734V8.58002H49.186V10.4473H54.0815V12.7041H46.5308Z"
                    fill="#003A8C" />
                <path
                    d="M63.6252 12.7041L61.3862 9.21751L59.1472 12.7041H55.9974L59.5957 7.38152L56.2298 2.32318H59.3646L61.3874 5.5756L63.3792 2.32318H66.529L63.1779 7.3665L66.7601 12.7054H63.6252V12.7041Z"
                    fill="#003A8C" />
                <path
                    d="M68.9978 2.3219H71.7003V8.40719C71.7003 9.63702 72.3489 10.5237 73.7852 10.5237C75.2215 10.5237 75.854 9.63702 75.854 8.40719V2.3219H78.5564V8.48609C78.5564 11.0697 77.089 12.8907 73.7852 12.8907C70.4814 12.8907 68.9978 11.0535 68.9978 8.50112V2.3219Z"
                    fill="#003A8C" />
                <path
                    d="M82.5412 9.23253C83.2829 9.98019 84.4248 10.6026 85.8301 10.6026C86.7409 10.6026 87.2975 10.2131 87.2975 9.71594C87.2975 9.12482 86.634 8.89063 85.5369 8.65769C83.8383 8.31579 81.4602 7.87996 81.4602 5.4203C81.4602 3.67699 82.9276 2.16663 85.5679 2.16663C87.2205 2.16663 88.6568 2.66507 89.7527 3.61437L88.3014 5.52925C87.4367 4.81289 86.3097 4.45472 85.3977 4.45472C84.4857 4.45472 84.1776 4.81289 84.1776 5.26375C84.1776 5.80853 84.8112 5.99514 85.9531 6.21305C87.6516 6.57123 89.9987 7.06967 89.9987 9.41913C89.9987 11.5043 88.4704 12.8895 85.7058 12.8895C83.6209 12.8895 82.1697 12.2357 81.1346 11.2401L82.5399 9.23253H82.5412Z"
                    fill="#003A8C" />
                <path
                    d="M33.7229 28.7984V18.4162H41.2737V20.673H36.3782V22.4013H41.1656V24.6743H36.3782V28.7984H33.7217H33.7229Z"
                    fill="#003A8C" />
                <path
                    d="M49.2133 18.2458C52.3631 18.2458 54.7114 20.4563 54.7114 23.616C54.7114 26.7758 52.3643 28.9862 49.2133 28.9862C46.0623 28.9862 43.7003 26.7758 43.7003 23.616C43.7003 20.4563 46.0636 18.2458 49.2133 18.2458ZM49.2133 20.6116C47.4999 20.6116 46.4028 21.9191 46.4028 23.616C46.4028 25.313 47.4987 26.6205 49.2133 26.6205C50.928 26.6205 52.009 25.298 52.009 23.616C52.009 21.9341 50.9131 20.6116 49.2133 20.6116Z"
                    fill="#003A8C" />
                <path
                    d="M63.1419 28.7984L61.4123 25.2805H60.053V28.7984H57.3965V18.4162H62.5541C64.8391 18.4162 66.1363 19.9416 66.1363 21.8715C66.1363 23.6774 65.0404 24.6418 64.0824 24.9849L66.1823 28.7984H63.1406H63.1419ZM62.1541 20.673H60.0542V23.0074H62.1541C62.8486 23.0074 63.4363 22.5566 63.4363 21.8402C63.4363 21.1238 62.8499 20.673 62.1541 20.673Z"
                    fill="#003A8C" />
                <path
                    d="M74.3692 18.2458C76.6244 18.2458 78.0135 19.3667 78.7702 20.6579L76.5461 21.8414C76.1286 21.1564 75.3421 20.6116 74.3692 20.6116C72.6856 20.6116 71.4965 21.9191 71.4965 23.616C71.4965 25.313 72.6856 26.6205 74.3692 26.6205C75.1719 26.6205 75.8975 26.3399 76.315 25.9981V25.1264H73.9517V22.9009H78.9243V26.9636C77.8122 28.1935 76.315 28.9875 74.3692 28.9875C71.2965 28.9875 68.7941 26.9173 68.7941 23.6173C68.7941 20.3173 71.2952 18.2471 74.3692 18.2471V18.2458Z"
                    fill="#003A8C" />
            </g>

            <g class="logo-icon">
                <path
                    d="M11.5491 6.46225L12.1729 7.09094L13.2079 6.60251L13.6378 7.03583L13.1806 7.99766L14.3696 9.17614L16.872 6.65386L15.7426 5.51545L14.7983 5.91872L14.2876 5.40399L14.7449 4.52357L11.3578 1.10959C11.3578 1.10959 9.45923 -0.94931 6.9767 0.541017C6.9767 0.541017 10.1936 1.31749 10.3451 2.65377C10.4321 3.6419 9.60461 4.43215 9.60461 4.43215L10.3563 5.18983L0 15.8288L1.13317 16.9709L11.0906 6.93189L11.5479 6.46099L11.5491 6.46225Z"
                    fill="black" />
                <path d="M18.6318 10.9359L9.70459 10.8683L9.63759 19.8664L18.5648 19.9339L18.6318 10.9359Z"
                    fill="#003A8C" />
                <path d="M27.4753 20.0008L18.5481 19.9333L18.4811 28.9314L27.4083 28.9989L27.4753 20.0008Z"
                    fill="black" />
                <path d="M9.65424 19.8685L0.727051 19.801L0.660052 28.799L9.58724 28.8666L9.65424 19.8685Z"
                    fill="black" />
                <path d="M27.5905 2.02059L18.6633 1.95306L18.5963 10.9511L27.5235 11.0187L27.5905 2.02059Z"
                    fill="black" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_254_2293">
                <rect width="90" height="29" fill="white" />
            </clipPath>
        </defs>
    </svg>
</div>