<app-add-form-field class="add-form" />
@if (formFields$ | async) {
<app-form-fields-list [fieldList]="formFields" [formId]="formId" (openFieldSettings)="openSettingsDrawer($event)"
    (openGeneralSettings)="openGeneralSettings()" [displayConfig]="displayConfig ?? null" />
}

<nz-drawer [nzVisible]="fieldEditorVisible" [nzWidth]="640" [nzClosable]="false" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>

        @if (fieldEditorVisible && selectedFormField && (fieldMasterData$ |async)) {
        <app-update-form-field [formId]="formId" [fieldData]="selectedFormField"
            [validationList]="fieldMasterData[selectedFormField.fieldType]" />
        }
    </ng-container>
</nz-drawer>
<nz-drawer [nzVisible]="generalEditorVisible" [nzWidth]="640" [nzClosable]="false" (nzOnClose)="closeGeneral()">
    <ng-container *nzDrawerContent>
        @if(generalEditorVisible){
        <app-form-general-settings [displayConfig]="displayConfig ?? null" [formId]="formId"
            (closeGeneralSettings)="closeGeneral()" />
        }
    </ng-container>
</nz-drawer>