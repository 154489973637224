import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FieldType, IDisplayConfig, IFieldOrderRequest, IFormField, IValidation, ValidationRules } from '../../models/forms';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray, CdkDragHandle } from '@angular/cdk/drag-drop';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormBuilderService } from '../../services/form-builder.service';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { CommonModule } from '@angular/common';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

@Component({
  selector: 'app-form-fields-list',
  standalone: true,
  imports: [CommonModule, CdkDropList, CdkDrag, CdkDragHandle, NzIconModule, NzPopconfirmModule, NzInputModule, NzFormModule, NzInputNumberModule, NzRadioModule, NzSelectModule, NzTypographyModule],
  templateUrl: './form-fields-list.component.html',
  styleUrl: './form-fields-list.component.scss'
})
export class FormFieldsListComponent {

  @Input() fieldList!: IFormField[];
  @Input() formId!: string;
  @Input() displayConfig: IDisplayConfig | null = null;

  @Output() openFieldSettings = new EventEmitter<string>();
  @Output() openGeneralSettings = new EventEmitter<boolean>();

  fieldType = FieldType

  private builderService = inject(FormBuilderService);

  drop(event: CdkDragDrop<string[]>) {

    if (event.previousIndex === event.currentIndex) return;

    const request: IFieldOrderRequest = {
      "fieldId": this.fieldList[event.previousIndex].fieldId,
      "formId": this.formId,
      "newPosition": event.currentIndex + 1
    }
    moveItemInArray(this.fieldList, event.previousIndex, event.currentIndex);


    this.builderService.updateFieldPosition(request);
  }

  isRequired(validations: IValidation[]) {
    return validations.some(validation => validation.rule === ValidationRules.REQUIRED && validation.value === 'true')
  }

  confirmDelete(fieldId: string) {
    const req = {
      fieldId,
      formId: this.formId,
    }

    this.builderService.deleteField(req);
  }

  openSetting(fieldId: string) {
    this.openFieldSettings.emit(fieldId);
  }

  openFormGeneralSettings() {
    this.openGeneralSettings.emit(true);
  }

}
