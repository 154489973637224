import { Injectable } from "@angular/core";
import { FormService } from "./form.service";
import { BehaviorSubject } from "rxjs";
import { IFieldDeleteRequest, IFieldOrderRequest, IFieldTypes, IFieldUpdateRequest, IForm, IFormField, IFormFieldRequest } from "../models/forms";
import { HttpService } from "./http.service";
import { NzMessageService } from "ng-zorro-antd/message";

@Injectable({
    providedIn: 'root'
})
export class FormBuilderService {

    loading$ = new BehaviorSubject<boolean>(false);
    fieldsData$ = new BehaviorSubject<IFieldTypes | null>(null)
    dateFormats$ = new BehaviorSubject<string[]>([])
    resetForm$ = new BehaviorSubject(false);


    constructor(private httpService: HttpService, private formService: FormService, private message: NzMessageService,) { }

    getFormData(formId: string) {
        if (!this.formService.formSubject$.value) {
            this.formService.getForm(formId);
        }
    }

    getFieldsMasterData() {
        this.loading$.next(true);
        this.httpService.getFieldsMasterData().subscribe({
            next: res => {
                this.loading$.next(false);
                this.fieldsData$.next(res.formFields);
            },
            error: res => {
                this.loading$.next(false);
            },
        })
    }

    getDateFormats() {
        this.loading$.next(true);
        this.httpService.getDateFormats().subscribe({
            next: res => {
                this.loading$.next(false);
                this.dateFormats$.next(res.data);
            },
            error: res => {
                this.loading$.next(false);
            },
        })
    }

    addFormField(field: IFormFieldRequest) {
        this.loading$.next(true);
        this.httpService.addFormField(field).subscribe({
            next: res => {
                this.loading$.next(false);
                this.message.create('success', 'Field Successfully added', { nzDuration: 5000 });
                this.resetForm$.next(true);
                this.formService.getForm(field.formId)
            },
            error: res => {
                this.loading$.next(false);
                this.message.create('error', 'Field failed to create', { nzDuration: 5000 });
            },
        })
    }

    updateFieldPosition(req: IFieldOrderRequest) {
        this.loading$.next(true);
        this.httpService.changeFieldOrder(req).subscribe({
            next: res => {
                this.loading$.next(false);
                this.message.create('success', 'Field order updated', { nzDuration: 5000 });
                this.formService.getForm(req.formId)
            },
            error: res => {
                this.loading$.next(false);
                this.message.create('error', 'Field order update failed', { nzDuration: 5000 });
            },
        })
    }

    updateField(req: IFieldUpdateRequest) {
        this.loading$.next(true);
        this.httpService.updateField(req).subscribe({
            next: res => {
                this.loading$.next(false);
                this.message.create('success', 'Field updated', { nzDuration: 5000 });
                this.formService.getForm(req.formId)
            },
            error: res => {
                this.loading$.next(false);
                this.message.create('error', 'Field update failed', { nzDuration: 5000 });
            },
        })
    }

    deleteField(req: IFieldDeleteRequest) {
        this.loading$.next(true);
        this.httpService.fieldDelete(req).subscribe({
            next: res => {
                this.loading$.next(false);
                this.message.create('success', 'Field deleted', { nzDuration: 5000 });
                this.formService.getForm(req.formId)
            },
            error: res => {
                this.loading$.next(false);
                this.message.create('error', 'Field deletion failed', { nzDuration: 5000 });
            },
        })
    }

}