import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { IDisplayConfig } from '../../models/forms';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { FormService } from '../../services/form.service';
import { skip } from 'rxjs';

@Component({
  selector: 'app-form-general-settings',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NzFormModule, NzInputModule, NzButtonModule, NzTypographyModule],
  templateUrl: './form-general-settings.component.html',
  styleUrl: './form-general-settings.component.scss'
})
export class FormGeneralSettingsComponent implements OnInit {

  @Input() formId!: string;
  @Input() displayConfig: IDisplayConfig | null = null;
  @Output() closeGeneralSettings = new EventEmitter<boolean>();

  formService = inject(FormService);


  updateForm!: FormGroup
  loading$ = this.formService.loading$;

  ngOnInit(): void {
    this.updateForm = new FormGroup({
      backgroundColor: new FormControl(this.displayConfig?.backgroundColor),
      textColor: new FormControl(this.displayConfig?.textColor),
      primaryColor: new FormControl(this.displayConfig?.primaryColor),
      labelColor: new FormControl(this.displayConfig?.labelColor),
      primaryFont: new FormControl(this.displayConfig?.primaryFont),
      displayFont: new FormControl(this.displayConfig?.displayFont),
      formHeading: new FormControl(this.displayConfig?.formHeading, [Validators.required]),
      formSubHeading: new FormControl(this.displayConfig?.formSubHeading, [Validators.required]),
      submitButtonLabel: new FormControl(this.displayConfig?.submitButtonLabel, [Validators.required])
    })

    this.formService.displayConfigUpdateStatus$.pipe(skip(1)).subscribe(x => {
      if (x) {
        this.closeForm();
      }
    });
  }

  closeForm() {
    this.closeGeneralSettings.emit(true)
  }

  submitForm() {
    if (this.updateForm.valid) {
      this.formService.updateDisplayConfig(this.formId, this.updateForm.getRawValue())
    }
  }
}
