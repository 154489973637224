import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IFieldResponse, IQRAPIResponse } from '../../models/qr';
import { IDisplayConfig, IFormField } from '../../models/forms';
import { ContactCardComponent } from '../../ui-components/contact-card/contact-card.component';

@Component({
  selector: 'app-public-qr',
  standalone: true,
  imports: [ContactCardComponent],
  templateUrl: './public-qr.component.html',
  styleUrl: './public-qr.component.scss'
})
export class PublicQrComponent {

  private activatedRoute = inject(ActivatedRoute);

  data: IQRAPIResponse | null = null;

  formFields: IFormField[] = [];
  responses: Array<IFieldResponse & { key?: string }> = []
  displayConfig!: IDisplayConfig


  ngOnInit() {
    this.activatedRoute.data.subscribe(({ qrData }) => {
      this.formFields = qrData.formFields;
      this.responses = qrData.formResponses.map((x: IFieldResponse) => ({ ...x, key: this.formFields.find(y => y.fieldId === x.fieldId)?.fieldLabel }));
      this.displayConfig = qrData.formInfo?.displayConfig ?? null
    })
  }


}
