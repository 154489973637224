import { Pipe, PipeTransform } from '@angular/core';
import { S3Service } from '../services/s3.service';

@Pipe({
  name: 's3Url',
  standalone: true
})
export class S3UrlPipe implements PipeTransform {

  constructor(private s3: S3Service) { }

  transform(value: string, ...args: unknown[]): Promise<string> {
    return this.s3.generatePresignedUrl(value);
  }

}
