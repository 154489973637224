<button nz-button nzType="primary" (click)="openForm()">
    <span nz-icon nzType="plus"></span>
    Add Field
</button>

<nz-modal [(nzVisible)]="formVisible" (nzOnCancel)="handleCancel()" [nzFooter]="null">
    <ng-container *nzModalContent>

        <h5 nz-typography class="text-center text-primary title">Add Field</h5>

        @if (fieldData$ | async) {
        <form nz-form class="form" [formGroup]="form" nz-form nzLayout="vertical" (ngSubmit)="saveField()">
            <nz-form-item>
                <nz-form-label nzRequired>Label</nz-form-label>
                <nz-form-control nzErrorTip="Please enter a valid label">
                    <input nz-input formControlName="label" type="text" placeholder="Field Label" />
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired="true">Field Type</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter a valid field name">
                    <nz-select formControlName="fieldType" (ngModelChange)="fieldTypeChange($event)">
                        @for (item of fieldType; track $index) {
                        <nz-option [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                        }
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            @if (showOptions) {
            <nz-form-label nzRequired="true">Options</nz-form-label>
            <div formArrayName="options">
                <div *ngFor="let field of options.controls; let i = index">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Option is required" class="option-input">
                            <input nz-input [formControlName]=" i" placeholder="Enter option">
                            <button nzSize="large" nz-button (click)="removeOption(i)"><span nz-icon nzType="delete"
                                    nzTheme="outline"></span></button>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <nz-form-item>
                <nz-form-control [nzXs]="{ span: 24, offset: 0 }">
                    <button type="button" nz-button nzType="dashed" class="add-button" (click)="addOption()">
                        <span nz-icon nzType="plus"></span>
                        Add Option
                    </button>
                </nz-form-control>
            </nz-form-item>
            }

            <div class="form-button">
                <button [disabled]="!form.valid" nz-button nzType="primary" type="submit" class="primary"
                    [nzLoading]="loading$ | async">
                    Create Field
                </button>
            </div>
        </form>
        }

    </ng-container>
</nz-modal>