import { Component, OnInit, inject } from '@angular/core';
import { HeaderComponent } from '../../ui-components/header/header.component';
import { AddEditQrResponseComponent } from '../../ui-components/add-edit-qr-response/add-edit-qr-response.component';
import { FormService } from '../../services/form.service';
import { CommonModule } from '@angular/common';
import { ResponsesService } from '../../services/responses.service';
import { combineLatestWith, map } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ContactCardComponent } from '../../ui-components/contact-card/contact-card.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { S3UrlPipe } from '../../pipes/s3-url.pipe';
import { DateAgoPipe } from '../../pipes/date-ago.pipe';

@Component({
  selector: 'app-qr-response-edit',
  standalone: true,
  imports: [HeaderComponent, AddEditQrResponseComponent, CommonModule, NzDrawerModule, NzButtonModule, NzIconModule, ContactCardComponent, NzModalModule, S3UrlPipe, DateAgoPipe],
  templateUrl: './qr-response-edit.component.html',
  styleUrl: './qr-response-edit.component.scss'
})
export class QrResponseEditComponent {

  private formService = inject(FormService);
  private responseService = inject(ResponsesService)
  private activatedRoute = inject(ActivatedRoute);


  reponseId = this.activatedRoute.snapshot.params['responseId'];

  preview = false
  qrPreview = false;

  form$ = this.formService.formSubject$;

  response$ = this.responseService.data$.pipe(
    map(x => x.data),
    combineLatestWith(this.formService.formSubject$),
    map(([e1, e2]) => {
      if (!e2) return null;
      const res = e1.map(response => ({
        ...response,
        formResponse: response.formResponse.map(field => ({ ...field, key: e2.formFields.find(x => x.fieldId === field.fieldId)?.fieldLabel })),
        displayConfig: e2.displayConfig ?? null
      }))
      return res
    },
    ),
    map(x => x?.find(y => y.id === this.reponseId))
  )

  openPreview() {
    this.preview = true;
  }

  closePreview() {
    this.preview = false;
  }

}
