import { Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { IDisplayConfig, IForm, IPublicGenericFormResponse } from '../models/forms';
import { HttpService } from './http.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  // behaviour subject of IForm
  formSubject$ = new BehaviorSubject<IForm | null>(null);
  loading$ = new BehaviorSubject<boolean>(false);
  publicGenericFormSubject$ = new BehaviorSubject<IPublicGenericFormResponse | null>(null)
  errorList$ = new BehaviorSubject<{ [key: string]: string }>({})
  resetForm$ = new BehaviorSubject(false);
  displayConfigUpdateStatus$ = new BehaviorSubject(false);

  constructor(private httpService: HttpService, private message: NzMessageService, private router: Router) { }

  getForm(id: string) {
    this.httpService.getForm(id).subscribe(form => {
      this.formSubject$.next(form.recordInfo);
    });
  }

  resolveForm(id: string) {
    return this.httpService.getForm(id).pipe(
      map(form => form.recordInfo),
      tap(res => { this.formSubject$.next(res) })
    )
  }

  getPublicGenericForm(id: string) {
    this.httpService.getGenericFormPublic(id).subscribe(form => {
      this.publicGenericFormSubject$.next(form);
    });
  }

  resolvePublicGenericForm(id: string) {
    return this.httpService.getGenericFormPublic(id).pipe(
      tap(res => { this.publicGenericFormSubject$.next(res) })
    )
  }

  submitPublicGenericForm(formId: string, formData: FormData) {
    this.loading$.next(true);
    this.httpService.submitPublicGenericFormResponse(formId, formData).subscribe({
      next: res => {
        this.loading$.next(false);
        this.message.create('success', 'Form Successfully Submitted', { nzDuration: 5000 });
        this.resetForm$.next(true);
      },
      error: res => {
        this.errorList$.next(res.error.errorlist)
        this.message.create('error', 'Form Submission failed', { nzDuration: 5000 });
        this.loading$.next(false);
      },
    })
  }

  addResponse(formData: FormData, formId: string) {
    this.loading$.next(true);
    this.httpService.addResponse(formData, formId).subscribe({
      next: res => {
        this.loading$.next(false);
        this.router.navigate(['form', formId])
        this.message.create('success', 'QR Data successfully saved');
      },
      error: res => {
        this.loading$.next(false);
      },
    })
  }

  updateResponse(formData: FormData, formId: string, responseId: string) {
    this.loading$.next(true);
    this.httpService.updateResponse(formData, formId, responseId).subscribe({
      next: res => {
        this.loading$.next(false);
        this.router.navigate(['form', formId])
        this.message.create('success', 'QR Data successfully saved');
      },
      error: res => {
        this.loading$.next(false);
      },
    })
  }

  updateDisplayConfig(formId: string, config: IDisplayConfig) {
    this.loading$.next(true);
    this.httpService.updateDisplayConfig(formId, config).subscribe({
      next: res => {
        this.loading$.next(false);
        const form = this.formSubject$.value
        if (form) {
          form.displayConfig = config
        }
        this.formSubject$.next(form);
        this.displayConfigUpdateStatus$.next(true)
        this.message.create('success', 'Style successfully updated');
      },
      error: res => {
        this.loading$.next(false);
        this.displayConfigUpdateStatus$.next(false)
        this.message.create('error', 'Something went wrong. Please try again');
      },
    })
  }


  updateFormStatus(formId: string, isEnabled: boolean) {
    this.loading$.next(true);
    this.httpService.updateFormStatus({ formId, isEnabled }).subscribe({
      next: res => {
        this.loading$.next(false);
        const form = this.formSubject$.value
        if (form) {
          form.isPublic = isEnabled
        }
        this.formSubject$.next(form);
        this.message.create('success', 'Form status successfully updated');
      },
      error: res => {
        this.loading$.next(false);
        const form = this.formSubject$.value
        if (form) {
          form.isPublic = !isEnabled
        }
        this.message.create('error', 'Something went wrong. Please try again');
      },
    })
  }


}
