import { HttpEvent, HttpInterceptorFn } from '@angular/common/http';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {

  const accessToken = sessionStorage.getItem('authToken');

  if (accessToken) {

    req = req.clone({
      setHeaders: { Authorization: `Bearer ${accessToken}` },
    });
  }


  return next(req).pipe(x => handleErrors(x, req.url));
};

const handle401 = () => {

  sessionStorage.removeItem('authToken');
  // show warning and once that is done close window
  window.close();
  return EMPTY;
}

const handleErrors = (
  source: Observable<HttpEvent<unknown>>,
  urlPath: string
) => {
  return source.pipe(
    catchError(err => {
      if (err.status === 401 && !urlPath.includes('/auth/')) {
        debugger;
        return handle401();
      }
      // rethrow error
      return throwError(() => err);
    })
  )
}

