import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { NzMenuModule } from 'ng-zorro-antd/menu';
import { FormService } from '../../../services/form.service';
import { map } from 'rxjs/internal/operators/map';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [NzMenuModule, RouterModule, NzIconModule, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {

  formService = inject(FormService);

  formId$ = this.formService.formSubject$.pipe(map(x => x?.id));
  formType$ = this.formService.formSubject$.pipe(map(x => x?.formType))
}
