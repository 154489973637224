<div nz-row nzJustify="center" nzAlign="middle">
    <div nz-col [nzSpan]="18">
        <form nz-form [formGroup]="formGroup" nz-form nzLayout="vertical">
            <div nz-row [nzGutter]="36">
                <ng-container *ngFor="let field of form.formFields">


                    @if(field.fieldType === fieldType.Image){
                    <div nz-col [nzSpan]="24">
                        <nz-upload nzListType="picture-card"
                            [nzShowButton]="!formGroup.get(field.fieldId)?.getRawValue()" [nzPreview]="handlePreview"
                            [(nzFileList)]="fileList" (nzChange)="handleFile($event, field.fieldId)">
                            <div>
                                <span nz-icon nzType="plus"></span>
                                <div style="margin-top: 8px">Upload</div>
                            </div>
                        </nz-upload>
                        <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                            (nzOnCancel)="previewVisible = false">
                            <ng-template #modalContent>
                                <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                            </ng-template>
                        </nz-modal>
                        <input type="hidden" [formControlName]="field.fieldId" [id]="field.fieldId" />
                    </div>
                    }
                    @else {
                    <div nz-col [nzSpan]="8">
                        <nz-form-item>
                            <nz-form-label [nzRequired]="isRequired(field.validations)" [nzFor]="field.fieldId">{{
                                field.fieldLabel }}</nz-form-label>
                            <nz-form-control [nzErrorTip]="getErrorMsg(field)">

                                <input *ngIf="field.fieldType === 'text'" nz-input [formControlName]="field.fieldId"
                                    [placeholder]="field.placeHolder" [id]="field.fieldId" />

                                <textarea *ngIf="field.fieldType === 'textarea'" nz-input
                                    [formControlName]="field.fieldId" [placeholder]="field.placeHolder"
                                    [nzAutosize]="{ minRows: 3, maxRows: 5 }" [id]="field.fieldId"></textarea>


                                <!-- Add more field types here as needed -->
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    }
                </ng-container>
            </div>

            <div class="form-buttons">
                <button nz-button nzType="default" (click)="cancelForm()">Cancel</button>
                <button nz-button nzType="primary" [disabled]="formGroup.invalid" [nzLoading]="loading$ | async"
                    (click)="submitForm()">Save</button>
            </div>

        </form>
    </div>
</div>