<nz-page-header class="site-header" (nzBack)="onBack()" [nzBackIcon]=" showBack ? '' : null">


    <nz-page-header-title class="site-title">
        <h4 nz-typography>{{title$ | async}}</h4>
        <p nz-typography>{{description$ |async}}</p>
    </nz-page-header-title>

    <nz-page-header-extra>
        <ng-content>

        </ng-content>
    </nz-page-header-extra>
</nz-page-header>