<app-header>
    <button nz-button nzType="primary" (click)="showModal()">
        <span nz-icon nzType="delete" nzTheme="outline"></span>
        Empty Trash
    </button>
</app-header>
<nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()" nzCentered [nzFooter]="null">
    <ng-container *nzModalContent>
        <h5 nz-typography class="text-center title" nzType="danger">Confirm to Empty Trash</h5>
        <div class="content">
            <div class="warning">
                <span nz-icon nzType="warning" nzTheme="outline" style="font-size: 32px; color: #ff4d4f"></span>
                <p>
                    Are you sure you want to empty all the responses of <span class="text-primary">'{{(form$ |
                        async)?.formName}}'</span>
                    from trash.
                </p>
            </div>
            <p class="footnote">The deleted responses will no longer be available, and can never be restored</p>
            <div class="form-buttons">
                <button (click)="handleCancel()" nz-button nzType="default">
                    Cancel
                </button>
                <button nz-button nzType="primary" type="submit" class="danger" (click)=" deleteFormConfirm()"
                    [disabled]="listLoading$ | async">
                    confirm
                </button>
            </div>
        </div>
    </ng-container>
</nz-modal>


<nz-skeleton [nzActive]="true" [nzLoading]="(listLoading$ | async) || false">

    @if((form$ |async)?.formType === formTypes.QRCODE){
    <div nz-row [nzGutter]="[24, 24]">
        @for (item of responses$ | async; track $index){
        <div nz-col [nzSpan]="6">
            <app-qr-trash-response-card [response]="item" />
        </div>
        }
    </div>
    }

    @if ((form$ |async)?.formType === formTypes.GENERIC) {
    <app-form-trash-table [responses]="responses$ | async" [formId]="formId" />
    }
</nz-skeleton>