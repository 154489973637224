import { Component, OnInit, inject } from '@angular/core';
import { ResponsesService } from '../../services/responses.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subject, Subscription, combineLatestWith, debounceTime, map, takeUntil } from 'rxjs';
import { FormService } from '../../services/form.service';
import { FormResponsesTableComponent } from '../../ui-components/form-responses-table/form-responses-table.component';
import { FormsModule, ReactiveFormsModule, FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ICondition } from '../../models/qr';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { IFilterCondition, IFilterResponse, IForm, IFormField } from '../../models/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

@Component({
  selector: 'app-generic-responses',
  standalone: true,
  imports: [CommonModule, FormResponsesTableComponent, FormsModule, NzFormModule, NzInputModule, NzIconModule, NzButtonModule, NzDrawerModule, NzTypographyModule, ReactiveFormsModule, NzSelectModule, NzBadgeModule],
  templateUrl: './generic-responses.component.html',
  styleUrl: './generic-responses.component.scss'
})
export class GenericResponsesComponent implements OnInit {


  private responseService = inject(ResponsesService)
  private formService = inject(FormService);
  private activatedRoute = inject(ActivatedRoute);
  formId = this.activatedRoute.snapshot.params['formId'];
  filterVisible = false;
  filterMasterData!: IFilterResponse[];
  formFieldData!: IFormField[];

  filtersForm = new FormGroup({
    filterArray: new FormArray([])
  })

  searchQuery = '';
  filterConditions: ICondition[] = []
  private searchSubject: Subject<string> = new Subject();
  private destroy$: Subject<void> = new Subject<void>();
  routeSub: Subscription[] = [];

  constructor() {
    this.searchSubject.pipe(
      debounceTime(500), // Adjust debounce time as needed
      takeUntil(this.destroy$)
    ).subscribe(searchText => {
      this.performSearch(searchText);
    });
  }

  ngOnInit(): void {
    this.responseService.getFilterMasterData();
    this.responseService.getAllResponses(this.formId);
    this.responseService.filtersMasterData$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (!!!x) return;
      this.filterMasterData = x;
    })

    this.routeSub.push(this.activatedRoute.data.subscribe(({ form }) => {
      if (!form) return
      this.formFieldData = form.formFields as IFormField[];
    }))
  }

  response$ = this.responseService.data$.pipe(
    map(x => x.data),
    combineLatestWith(this.formService.formSubject$),
    map(([e1, e2]) => {
      if (!e2) return null;
      const res = e1.map(response => ({
        ...response,
        formResponse: response.formResponse.map(field => ({ ...field, key: e2.formFields.find(x => x.fieldId === field.fieldId)?.fieldLabel })),
      }))
      return res
    },
    ),
  )

  performSearch(searchText: string): void {
    this.responseService.getAllResponseWithCondition(this.formId, searchText, this.filterConditions)
  }


  onSearch(searchText: string): void {
    this.searchSubject.next(searchText);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.routeSub.forEach(x => x.unsubscribe);
  }


  openFilter() {
    this.filterVisible = true;
    this.clearFilters();
    this.filterConditions.forEach(x => this.addOldFilter(x))
  }

  clearFilters() {
    this.filtersForm = new FormGroup({
      filterArray: new FormArray([])
    })
  }

  clearAll() {
    this.clearFilters();
    this.filterConditions = [];
    this.performSearch(this.searchQuery);
    this.closeFilter();
  }

  closeFilter() {
    this.filterVisible = false;
  }

  get filters(): FormArray {
    return this.filtersForm.get('filterArray') as FormArray;
  }

  addFilter(): void {
    this.filters.push(new FormGroup({
      fieldId: new FormControl('', Validators.required),
      condition: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required)
    }));
  }

  addOldFilter(condition: ICondition) {
    this.filters.push(new FormGroup({
      fieldId: new FormControl(condition.fieldId, Validators.required),
      condition: new FormControl(condition.condition, Validators.required),
      value: new FormControl(condition.value, Validators.required)
    }));
  }

  changeConditionValues(index: number) {
    const group = this.filters.at(index)
    group.get('condition')?.setValue('');
  }

  removeFilter(index: number): void {
    this.filters.removeAt(index);
  }

  condition(index: number): IFilterCondition[] {
    let fieldId = this.filters.at(index).get('fieldId')?.value
    if (!fieldId) return [];
    let dataType = this.formFieldData.find(x => x.fieldId === fieldId)?.dataType
    if (!dataType) return [];
    const conditions = this.filterMasterData.find(x => x.dataType === dataType)?.conditions
    return conditions ? conditions : [];
  }

  ApplyFilters() {
    this.filterConditions = this.filtersForm.getRawValue().filterArray
    this.performSearch(this.searchQuery);
    this.closeFilter();
  }

}
