<nz-table #basicTable [nzData]="responses ?? []">
    <thead>
        @if (responses?.length) {
        <tr>
            @for (item of responses ? responses[0].formResponse : []; track $index) {
            <th>{{item.key}}</th>
            }
            <th>Action</th>
        </tr>
        }
    </thead>
    <tbody>
        @for (data of basicTable.data; track $index) {
        <tr>
            @for (item of data.formResponse; track $index) {
            <td>{{item.stringValue || item.doubleValue}}</td>
            }
            <td>
                <a nz-typography nzType="danger" nz-popconfirm nzPopconfirmTitle="Are you sure?" nzOkText="ok"
                    nzCancelText="cancel" (nzOnConfirm)="confirmDelete(data.id)">
                    Delete
                </a>
            </td>
        </tr>
        }
    </tbody>
</nz-table>