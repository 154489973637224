import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormBuilderService } from '../../services/form-builder.service';
import { CommonModule } from '@angular/common';
import { Subscription, map, skip, tap } from 'rxjs';
import { toSentenceCase } from '../../utils/text';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormService } from '../../services/form.service';
import { FieldType } from '../../models/forms';

@Component({
  selector: 'app-add-form-field',
  standalone: true,
  imports: [NzModalModule, NzButtonModule, NzIconModule, CommonModule, NzTypographyModule, ReactiveFormsModule, NzFormModule, NzInputModule, NzSelectModule],
  templateUrl: './add-form-field.component.html',
  styleUrl: './add-form-field.component.scss'
})
export class AddFormFieldComponent implements OnInit, OnDestroy {

  private builderService = inject(FormBuilderService);
  private formService = inject(FormService);


  formVisible = false;
  fieldType: { label: string, value: string }[] = []
  fieldData$ = this.builderService.fieldsData$.pipe(
    tap(x => {
      if (!x) return;
      this.fieldType = Object.keys(x).map(item => ({ label: toSentenceCase(item), value: item }))
    }),
    map(x => x)
  )
  loading$ = this.builderService.loading$;
  subscriptions: Subscription[] = [];
  showOptions = false;

  form: FormGroup = new FormGroup({
    formId: new FormControl(''),
    fieldType: new FormControl(null, [Validators.required]),
    placeHolder: new FormControl(''),
    label: new FormControl('', [Validators.required]),
    toolTip: new FormControl(''),
    disabled: new FormControl(false),
    options: new FormArray([]),
    validationRules: new FormArray([])
  })

  addValidation() {
    const item = new FormGroup({
      rule: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required])
    });

    // Add the new form group to the FormArray
    this.validations.push(item);

  }

  get validations() {
    return this.form.get('validationRules') as FormArray;
  }

  formReset() {
    this.form = new FormGroup({
      formId: new FormControl(''),
      fieldType: new FormControl(null, [Validators.required]),
      placeHolder: new FormControl(''),
      label: new FormControl('', [Validators.required]),
      toolTip: new FormControl(''),
      disabled: new FormControl(false),
      options: new FormArray([]),
      validationRules: new FormArray([])
    })
  }

  openForm() {
    this.formVisible = true;
  }

  handleCancel() {
    this.formVisible = false
    this.formReset();
    this.showOptions = false;
  }

  saveField() {
    const formValue = this.form.getRawValue();
    formValue.formId = this.formService.formSubject$.value?.id
    this.builderService.addFormField(formValue);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.builderService.resetForm$.pipe(skip(1)).subscribe(x => {
        if (x) {
          this.handleCancel();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe())
  }

  fieldTypeChange(value: string) {
    if ([FieldType.Radio, FieldType.MultiSelect, FieldType.Select].includes(value as FieldType)) {
      this.showOptions = true;
      return;
    }
    this.showOptions = false;
    this.options.value.forEach((x: string) => this.options.removeAt(0));
  }

  get options(): FormArray {
    return this.form.get('options') as FormArray;
  }

  addOption(): void {
    this.options.push(new FormControl('', [Validators.required]));
  }

  removeOption(index: number): void {
    this.options.removeAt(index);
  }

}
