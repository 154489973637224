import { Component, OnDestroy, inject } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { ContactCardComponent } from '../contact-card/contact-card.component';
import { FormService } from '../../services/form.service';
import { Subscription, map, skip, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IDisplayConfig } from '../../models/forms';
import { qrPreviewData } from './qr-preview-data';

import { NzColorPickerComponent } from 'ng-zorro-antd/color-picker';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormPreviewComponent } from '../form-preview/form-preview.component';

@Component({
  selector: 'app-basic-style-modal',
  standalone: true,
  imports: [NzButtonModule, NzIconModule, NzModalModule, NzTypographyModule, NzDrawerModule, ContactCardComponent, CommonModule, NzColorPickerComponent, ReactiveFormsModule, NzFormModule, NzInputModule, FormPreviewComponent],
  templateUrl: './basic-style-modal.component.html',
  styleUrl: './basic-style-modal.component.scss'
})
export class BasicStyleModalComponent implements OnDestroy {

  defaulDisplayConfig = {
    backgroundColor: "#fff",
    displayFont: '"DM Serif Display", serif',
    labelColor: "#595959",
    primaryColor: "#003A8C",
    primaryFont: '"Archivo", sans-serif',
    textColor: "#434343",
    formHeading: "no heading",
    formSubHeading: "no sub",
    submitButtonLabel: "no label"
  }

  resetValue!: IDisplayConfig;
  valueChangesSubscription!: Subscription;
  formId!: string

  formService = inject(FormService);
  displayConfig: IDisplayConfig | null = null;
  displayConfigForm: FormGroup = new FormGroup({
    backgroundColor: new FormControl(this.defaulDisplayConfig.backgroundColor, [Validators.required]),
    displayFont: new FormControl(this.defaulDisplayConfig.displayFont, [Validators.required]),
    labelColor: new FormControl(this.defaulDisplayConfig.labelColor, [Validators.required]),
    primaryColor: new FormControl(this.defaulDisplayConfig.primaryColor, [Validators.required]),
    primaryFont: new FormControl(this.defaulDisplayConfig.primaryFont, [Validators.required]),
    textColor: new FormControl(this.defaulDisplayConfig.textColor, [Validators.required]),
    formHeading: new FormControl(this.defaulDisplayConfig.formHeading, [Validators.required]),
    formSubHeading: new FormControl(this.defaulDisplayConfig.formSubHeading, [Validators.required]),
    submitButtonLabel: new FormControl(this.defaulDisplayConfig.submitButtonLabel, [Validators.required]),
  })

  formType$ = this.formService.formSubject$.pipe(tap(x => {
    this.displayConfig = x?.displayConfig ?? { ...this.defaulDisplayConfig };
    this.resetValue = { ...this.displayConfig };
    this.formId = x?.id ?? ""
    this.resetValues()
    this.detectChanges();
  }), map(x => x?.formType));
  formLoading$ = this.formService.loading$
  formFields$ = this.formService.formSubject$.pipe(map(x => x?.formFields ?? []));

  isVisible = false;
  previewQRData = qrPreviewData


  showModal(): void {
    this.isVisible = true;
  }

  resetValues() {
    this.displayConfig = { ...this.resetValue };
    this.displayConfigForm.patchValue({
      backgroundColor: this.resetValue.backgroundColor,
      displayFont: this.resetValue.displayFont,
      labelColor: this.resetValue.labelColor,
      primaryColor: this.resetValue.primaryColor,
      primaryFont: this.resetValue.primaryFont,
      textColor: this.resetValue.textColor,
      formHeading: this.resetValue.formHeading,
      formSubHeading: this.resetValue.formSubHeading,
      submitButtonLabel: this.resetValue.submitButtonLabel,
    })
  }

  detectChanges() {
    this.valueChangesSubscription = this.displayConfigForm.valueChanges.subscribe(x => {
      this.displayConfig = x
    })
  }

  handleCancel() {
    this.resetValues();
    this.isVisible = false;
  }


  updateDisplayConfig() {
    if (this.displayConfigForm.valid) {
      this.formService.updateDisplayConfig(this.formId, this.displayConfigForm.getRawValue())
    }
  }

  ngOnInit(): void {
    this.formService.displayConfigUpdateStatus$.pipe(skip(1)).subscribe(x => {
      if (x) {
        this.resetValue = this.displayConfigForm.getRawValue();
        this.handleCancel();
      }
    });
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription.unsubscribe()
  }

}
